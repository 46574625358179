/*
 * PMApp::suspensions
 * Place JS to support suspension-related functionality in this file
 */

import { initializeDateTimePickers } from './date_time_pickers.js.erb';


// methods

/*
 * SetupFormEventHandlers
 * TODO
 */

export function suspensionsInitializeForm(allowNLEs, allowSchedule) {
  suspensionsHideShowNLESelect(allowNLEs);
  suspensionsInitializeScheduleTerminateControls(allowSchedule);

  // setup event handlers for the reason select
  $('#' + suspensionsBuildReasonSelectId()).change(function(event) {
    const e = event || window.event
    const triggeringSelect = e.target || e.srcElement;
    suspensionsHandleReasonSelectChange(triggeringSelect);
  });

  $('#' + suspensionsBuildTerminateCheckId()).change(function(event) {
    const e = event || window.event
    const triggeringCheck = e.target || e.srcElement;
    suspensionsHandleTerminateCheckChange(triggeringCheck);
  });
}

function suspensionsInitializeScheduleTerminateControls(allowSchedule) {
  initializeDateTimePickers();
  suspensionsHideShowTeminateTimePicker(allowSchedule);
}

export function suspensionsHideShowTeminateTimePicker(allowSchedule) {
  const terminateTimePickerId = suspensionsBuildTerminateTimePickerContainerId();
  if (allowSchedule) {
    $('#' + terminateTimePickerId).show();
  }
  else {
    $('#' + terminateTimePickerId).hide();
  }
}


// event handlers

/*
 * HandleReasonSelectChange
 * used to handle changes to the reason select on the suspension modal form
 * -- ---- -- -- --
 * triggeringSelect: a JS handle to the reasons select
 */
function suspensionsHandleReasonSelectChange(triggeringSelect) {
  const selectedReason = $(triggeringSelect).val();

  const params = { reason: selectedReason };
  const updateUrl = suspensionsGetUpdateUrl(triggeringSelect);

  $.ajax({
    data:  params,
    type:  'POST',
    url:   updateUrl,
    async: true
  });
}

/*
 * HandleTerminateCheckChange
 * on the suspend task modal, if the state of the "unsuspend at a scheduled time" check changes, we prompt
 * the user for the date/time at which the suspension should be terminated
 */
function suspensionsHandleTerminateCheckChange(triggeringCheck) {
  const isChecked = $(triggeringCheck).is(':checked');

  const params = { schedule: isChecked };
  const updateUrl = suspensionsGetUpdateUrl(triggeringCheck);

  $.ajax({
    data:  params,
    type:  'POST',
    url:   updateUrl,
    async: true
  });
}


// helpers

// BuildNLESelectId
function suspensionsBuildNLESelectContainerId() {
  return 'suspensionsNLESelectContainer';
}

// BuildReasonSelectId
function suspensionsBuildReasonSelectId() {
  return 'suspensionsReason';
}

// BuildTerminateCheckId
function suspensionsBuildTerminateCheckId() {
  return 'suspensionsTermCheckbox';
}

// BuildTerminateTimePickerContainerId
function suspensionsBuildTerminateTimePickerContainerId() {
  return 'endAtContainer';
}

/*
 * GetUpdateUrl
 * used to read a URL from a constrol; the read URL is supposedly for notifying the server of changes to the
 * control state
 * -- ---- -- -- --
 * triggeringControl: the subject control
 */

function suspensionsGetUpdateUrl(triggeringControl) {
  var updateUrlKey = 'url';
  return $(triggeringControl).data(updateUrlKey);
}

export function suspensionsHideShowNLESelect(allowNLEs) {
  const nleSelectContainerId = suspensionsBuildNLESelectContainerId();
  if (allowNLEs) {
    $('#' + nleSelectContainerId).show();
  }
  else {
    $('#' + nleSelectContainerId).hide();
  }
}

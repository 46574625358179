/*
 * PMApp::task_managers
 * This file contains JS that has been implemented specifically to support the workload management page.
 */

import { filterableClear,
         filterableCountEnabledFilterButtons,
         filterableInitializeItemListAsFilterable } from './filterable.js.erb';
import { itemListsSetZIndexes                     } from './item_lists.js.erb';
import { pmappPreventDefaults                     } from './pmapp.js.erb';
import { rosterableInitializeAsDraggable          } from "./rosterable.js.erb";
import { squirrelableGetData                      } from './squirrelable.js.erb';
import { taskListableGetItemCount,
         taskListableInitialize                   } from "./task_listable.js.erb";
import { teamFocusSetupEventHandlers              } from './team_focus.js.erb';
import { workerAvatarListInitializePopovers,
         workerAvatarListDestroyPopovers          } from './worker_avatar_list.js.erb';


// -- ---- -- -- --
// INITIALIZATION

$(document).on('turbolinks:load', function() {
  if ($('body.task_managers.index').length) {
    teamFocusSetupEventHandlers();
    taskListableInitialize('.entire-page',
                           '#pcdtDiv');

    // task list heading
    $('.task-listable').each(function (index, taskList) {
      const sectionName = $(taskList).data('section');
      filterableInitializeItemListAsFilterable(sectionName);

      taskManagersPopulateTaskListCount(sectionName);
      taskManagersHideShowFilterControls(sectionName);
    });

    taskManagersInitializeForDragAndDropAddTaskList();
    itemListsSetZIndexes();
  }
});


export function taskManagersSetupRefreshListEventHandler() {
  $('#refreshActionContainer a').on('click', function(event) {
    event = event || window.event
    pmappPreventDefaults(event);

    var icon = event.target || event.srcElement;
    var clickedLink = $(icon).closest('a');

    var squirreledParams = squirrelableGetData('refresh_list');
    var url = clickedLink.attr('href');

    $.ajax({
      data:  squirreledParams,
      type:  'POST',
      url:   url,
      async: true
    });
  });
}


// -- ---- -- -- --
// DRAG/DROP TASK LISTS

/*
 * taskManagersAcceptDropTeamMember
 * checks if a a team member (worker) from a team roster can be dropped on an open pipeline tasks hotspot
 * -- ---- -- -- --
 * teamMemberId: the ID of the team member in question
 */
function taskManagersAcceptDropTeamMember(teamMemberId) {
  var rVal = true;

  // don't accept if the pipeline task list for the team member in question is already open
  var taskListSectionName = 'sectionPipeline' + teamMemberId.toString();
  // TODO
  if ($('.task-listable[data-section=' + taskListSectionName + ']').length > 0) {
    rVal = false;
  }

  return rVal;
}

/*
 * taskManagersHandleDropTeamMember
 * handle the user dropping an team member (worker) from a team roster on an open pipeline tasks hotspot
 * -- ---- -- -- --
 * drop method for jQuery UI droppable
 */

function taskManagersHandleDropTeamMember(e) {
  e = e || window.event
  var droppable = e.target || e.srcElement;

  // NOTE - don't un-highlight the receiving droppable
  // leave that to the response, this way the accept state stays until the new list is added and we get
  // a less jumpy transition

  // handle the drop itself
  var teamMemberId = e.detail.teamMemberId;

  // the UI should have indicated that the team member could not be dropped on the hotspot, but there really
  // isn't anything preventing the user from doing it anyway, so we check to be sure it should be allowed
  // to make sure nothing happends
  if (taskManagersAcceptDropTeamMember(teamMemberId)) {
    // get the DOM index of the receiving element, relative to the parent, we will pass this back to the server
    // so the response will know where to insert the new list
    var receivingIndex = $(this).index();

    var openPipelineUrl = $(this).data("url");

    $.ajax({
      data:  { worker_id: teamMemberId,
               position:  receivingIndex },
      type:  'POST',
      url:   openPipelineUrl,
      async: true
    });
  }
}

/*
 * taskManagersInitializeForDragAndDropAddTaskList
 * this method sets up hot spots for dropping workers, from the team roster, in order to add worker pipeline
 * task lists to the workload management page
 */
export function taskManagersInitializeForDragAndDropAddTaskList() {
  // the server will decide if dragging team members to assign the project owner should be allowed
  // if so, there will be at least one element with the class PmappJobsController::SET_OWNER_HOTSPOT_CLASS_NAME
  rosterableInitializeAsDraggable('.entire-page',
                                  'add-list-hotspot');

    $('.add-list-hotspot').on("rosterableEnter", function (e) {
      e = e || window.event
      var droppable = e.target || e.srcElement;
      if (taskManagersAcceptDropTeamMember(e.detail.teamMemberId)) {
        $(droppable).addClass("accept");
      }
    });

    $('.add-list-hotspot').on("rosterableExit", function (e) {
      e = e || window.event
      var droppable = e.target || e.srcElement;
      $(droppable).removeClass("accept");
    });

    $('.add-list-hotspot').on("rosterableReceive",
                                                                       taskManagersHandleDropTeamMember);
}

/*
 * taskManagersRemoveDragAndDropListHandlers() {
 * use this to remove drag/drop add list event handlers; this is needed when lists are added and the new list
 # needs to be configured with handlers; if you don't remove handlers first, existingly lists will end up with
 * duplicate handlers
 */
export function taskManagersRemoveDragAndDropListHandlers() {
  $('.add-list-hotspot').off("rosterableEnter");
  $('.add-list-hotspot').off("rosterableExit");
  $('.add-list-hotspot').off("rosterableReceive");
}


// -- ---- -- -- --
// HELPERS
// -- ---- -- -- --

export function taskManagersHideShowFilterControls(sectionName) {
  var filterButtonSelector   = '#' + sectionName + 'FilterButton';
  var filterCollapseSelector = '#' + sectionName + 'FilterCollapse';

  var numActiveFilters = filterableCountEnabledFilterButtons(sectionName);

  if (numActiveFilters < 1) {
    // close the filter collapse
    $(filterCollapseSelector).collapse('hide');

    // hide the filter button
    $(filterButtonSelector).hide();

    // clear all filtering
    filterableClear(sectionName);
  }
  else {
    $(filterButtonSelector).show();
  }
}

export function taskManagersPopulateTaskListCount(sectionName) {
  const countSelector = '#' + sectionName + 'TaskListCount';
  const itemCount = taskListableGetItemCount(sectionName);

  $(countSelector).html(
    '(' + itemCount.toString() + ')'
  );
}

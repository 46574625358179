/*
 * PMApp::filterable
 * JS for the filterable component
 */

import { showLoadingOverlay                      } from "./loading_overlay";
import { pmappPreventDefaults                    } from './pmapp.js.erb';
import { jobListableFetchOtherParamsForFiltering } from './job_listable.js.erb';

// -- ---- -- -- --
// constants
// -- ---- -- -- --

const filterableButtonClassName              = 'filterable-common-button';
const filterableButtonSlugDataKey            = 'slug';
const filterableCountClassName               = 'filterable-actual-count';
const filterableDisabledClassName            = 'filterable-disabled-button';
const filterableEngagedClassName             = 'filterable-engaged';
const filterableItemCountDataKey             = 'filterable-item-count';
const filterableItemSlugsClassName           = 'filterable-slugs';
const filterableMinPageButtonHeight          =  18;
const filterableMutexContainerClassName      = 'filterable-mutex-container';
const filterableNegatedClassName             = 'negated';
const filterableNegateLinkClassName          = 'filterable-negate';
const filterableNegateLinkContainerClassName = 'filterable-negate-link-container';
const filterablePageControlsContainerId      = 'filterablePageLevelControlsContainer';
const filterablePageControlsHideLinkId       = 'filterablePageLevelControlsHideLink';
const filterablePageControlsResizeLinkId     = 'filterablePageLevelControlsResizeLink';
const filterablePageControlsToggleId         = 'filterablePageLevelControlsToggle';
const filterablePageSlideoutHeight           = '104px';
const filterablePageLevelPseudoSectionName   = 'PAGE';
const filterablePageSlideoutClassName        = 'filterable-page-level-slideout';
const filterableSectionDataKey               = 'filterable-section';
const filterableSlugsDataKey                 = 'slugs';

const filterableButtonDirectiveAll           = 'all';
const filterableButtonDirectiveAllMutex      = 'all mutually exclusive';
const filterableButtonDirectiveDisabledMutex = 'disabled mutually exclusive';
const filterableButtonDirectiveDisabledOnly  = 'disabled only';
const filterableButtonDirectiveEngagedOnly   = 'engaged only';

const filterableEngageDirectiveDisengage     = 'disengage';
const filterableEngageDirectiveEngage        = 'engage';
const filterableEngageDirectiveToggle        = 'toggle';

const filterableFilterKeyMutex               = 'mutex';
const filterableFilterKeyNegated             = 'negated';
const filterableFilterKeySlug                = 'slug';

const filterableNegateDirectiveNegate        = 'negate';
const filterableNegateDirectiveToggle        = 'toggle';
const filterableNegateDirectiveUnnegate      = 'un-negate';


// -- ---- -- -- --
// initialization
// -- ---- -- -- --

/*
 * filterableInitializeItemListAsFilterable
 * sets up the event handler for item list filter controls
 * -- ---- -- -- --
 * sectionName:           the section name of the item list for which we are initializing filering capability
 * generateOutsideParams: this should be a function that accepts a section name (to identify the subject item
 *                        list) and either returns "undefined" OR an object that can be used as params to send
 *                        to the server
 */
export function filterableInitializeItemListAsFilterable(sectionName, generateOutsideParams) {
  const buttons = filterableButtons(sectionName);

  buttons.forEach(function(button) {
    // set up click handlers for the filter button
    $(button).click(function(event) {
      filterableHandleFilterTap(event, generateOutsideParams);
    });

    // set up a click handlers for the "negate" links within the button, if the button is an "other" filter button
    filterableGetNegateLinks(button).forEach(function(negateButton) {
      $(negateButton).click(function(event) {
        filterableHandleNegateLinkTap(event, button, generateOutsideParams);
      });
    });
  });

  // set up a click handler for the reset link
  const controlSelector = filterableBuildFilterControlSelector(sectionName);
  const container = $(controlSelector).closest('.filterable-buttons-and-reset-link');
  const resetLink = $(container).find('.filterable-reset-container a');
  $(resetLink).click(function(event) {
    pmappPreventDefaults(event);
    var slugs; // undefined => clear for all slugs
    filterableClear(sectionName, slugs, generateOutsideParams);
  });

  if (filterableHandleClientSide(sectionName)) {
    // filterng for the item list identified by sectionName is to be handled client-side; in such cases, the
    // server will send all list items (as opposed to only sending this items that should be displayed) and
    // leave it to the JS to hide those list items that are "filtered out"; however, the server may also
    // send filtering state presets in which case we must apply the filters now, at initialization time;
    // further, the server will not have checked to see that the preset filtering state is "useful" so we
    // must also do that now
    filterableUpdateButtonStates(sectionName);
    filterableApplyFilters(sectionName);

    // since we may have altered the presets to make them useful, we must report the latest filtering state
    // to the server
    filterableReportCurrentFilteringState(sectionName, generateOutsideParams);
  }

  // size the filter buttons to fill the available space
  filterableProportionFilterButtons(sectionName, container);
}

/*
 * filterableInitializePageAsFilterable
 * sets up the event handlers for page level filter controls; returns a true if there is at least one page
 # level filter that will change the content of at least one job list when engaged; returns false otherwise
 * -- ---- -- -- --
 * pageCount:             the total number of filterable items represented on the page
 * generateOutsideParams: this should be a function that accepts a section name (to identify the subject item
 *                        list) and either returns "undefined" OR an object that can be used as params to send
 *                        to the server
 */
export function filterableInitializePageAsFilterable(pageCount, generateOutsideParams) {
  const returnValue = filterableUpdatePageLevelButtonCounts(pageCount);

  if (returnValue) {
    filterableSetupSlideoutEventHandlers();

    // there is at least one page level filter that will change the content of at least one job list when engaged
    const pageLevelControlSelector = filterableBuildFilterControlSelector(filterablePageLevelPseudoSectionName);

    // for each page level filter button...
    const pageButtons = filterableButtons(filterablePageLevelPseudoSectionName);
    pageButtons.forEach(function(button) {
      // set up click event handlers for the page level filter buttons
      $(button).click(function(event) {
        filterableHandlePageLevelFilterTap(event, generateOutsideParams);
      });

      // set up click event handlers for the negate links within the page level filter buttons
      filterableGetNegateLinks(button).forEach(function(negateButton) {
        $(negateButton).click(function(event) {
          filterableHandlePageLevelNegateLinkTap(event, button, generateOutsideParams);
        });
      }); // each negate link
    }); // each page-level button

    // set up a click handler for the reset link
    const container = $(pageLevelControlSelector).closest('.filterable-buttons-and-reset-link');
    const resetLink = $(container).find('.filterable-reset-container a');
    $(resetLink).click(function(event) {
      filterableHandlePageLevelResetLinkTap(event, generateOutsideParams);
    });

    filterableProportionFilterButtons(filterablePageLevelPseudoSectionName, container);
  }

  return returnValue;
}


// -- ---- -- -- --
// public functions
// -- ---- -- -- --

/*
 * filterableAdjustDeltasForNewItem
 * for server-side filtering; adding, updating, or removing an item from an item list can be handled, for
 * filterable purposes, without re-rendering the entire list, but to do so we must know how the item changes
 * will affect the filter button counts; this method, specifically, is used to update the changes to filter
 * button counts when an item is added to the list
 * -- ---- -- -- --
 * sectionName:  identifies the item list in question
 * itemSelector: a CSS selector that identifies the item that has been added
 * slugDeltas:   a slug to "changes" map that reflects the changes made prior to calling this method; 
 *               undefined if the new item is the first change to the item list
 */
export function filterableAdjustDeltasForNewItem(sectionName, itemSelector, slugDeltas) {
  var returnValue = slugDeltas;

  const listSlugs  = filterableGetItemListSlugs(sectionName);
  const mutexSlugs = listSlugs['mutex'];
  const otherSlugs = listSlugs['other'];

  const itemSlugs = filterableGetItemSlugs(itemSelector);

  // initialize the return value if necessary
  if (! returnValue) returnValue = filterableInitializeSlugDeltas(sectionName);

  // the item should only match one mutex slug and that slug's count needs to be decremented
  mutexSlugs.forEach(function(slug) {
    if (itemSlugs.includes(slug)) {
      if (returnValue[slug]) {
        returnValue[slug]['count'] += 1;
      }
      else {
        returnValue[slug] = { count: 1 };
      }
    }
  });

  // all non-mutex slugs will be affected; if the item matches the slug's count needs to be decremented;
  // if the item does not match, then the slug's negated count needs to be decremented
  otherSlugs.forEach(function(slug) {
    if (itemSlugs.includes(slug)) {
      returnValue[slug]['count'] += 1;
    }
    else {
      returnValue[slug]['negated'] += 1;
    }
  });

  return returnValue;
}

/*
 * filterableApplyFilters
 * call this method to apply the filters selected in the filter control to the respective item list; this method
 * is used for both client and server-side filtering
 * -- ---- -- -- --
 * sectionName: identifies the item list to which the subject filter control pertains
 */
export function filterableApplyFilters(sectionName) {
  const controlSelector = filterableBuildFilterControlSelector(sectionName);

  if ($(controlSelector).length > 0) {
    // there actually are filters to apply
    const itemClass = filterableGetListItemClass(controlSelector);
    var items = filterableGetListItems(controlSelector, sectionName);

    // build a list of all engaged buttons and use that to hide/show list items
    const simulateEmpty = true;
    const filters = filterableGetEngagedFiltersCore(sectionName, simulateEmpty);

    if (filters.length > 0) {
      // some filters are engaged, start by assuming they have all been filtered out; hide all the items
      $(items).hide();

      /*
       * NOTE - The behavior implemented here is also implemented on the server
       *        (see Filterable#filterable_filtered_out?) for applying filters server-side.  If you change
       *        the behavior implemented in this method you will need to change the behavior for server-side
       *        filtering to match.
       */

      items = filterableApplyMutexFilters(filters, items, itemClass);
      items = filterableApplyOtherFilters(filters, items, itemClass);

      // finally, show the items permitted by the filters, if any
      $(items).show();
    }
  }
}

/*
 * filterableClear
 * call this method to show all list items (client-side filtering), effectively clearing any filtering
 * -- ---- -- -- --
 * sectionName:           identifies the item list to which the subject filter control pertains
 * slugs:                 used to restrict the clear to a subset of slugs; undefined if the clear should
 *                        affect all slugs associated with the identified item list
 * generateOutsideParams: this should be a function that accepts a section name (to identify the subject item
 *                        list) and either returns "undefined" OR an object that can be used as params to send
 *                        to the server; if "undefined" filtering should be handled client-side; otherwise, the
 *                        returned object should contain any parameters that need to be sent to the server so
 *                        the server can handle filtering
 */
export function filterableClear(sectionName, slugs, generateOutsideParams) {
  const controlSelector = filterableBuildFilterControlSelector(sectionName);

  const filterControlContainer = $(controlSelector)[0];
  const engagedSlugsBefore = filterableGetEngagedFiltersSlugs(sectionName);
  filterableDisengageButtons(sectionName, slugs);
  filterableUnnegateButtons(sectionName, slugs);
  const engagedSlugsAfter = filterableGetEngagedFiltersSlugs(sectionName);

  // if engaging all buttons did not change anything, we are done
  if (engagedSlugsBefore.length != engagedSlugsAfter.length) {
    // show all items in the subject item list
    filterableApplyFilters(sectionName);

    // tell the server we just cleared the filter state
    filterableReportCurrentFilteringState(sectionName, generateOutsideParams);
  }
}

/*
 * filterableComputeDeltasForRemovedItem
 * for server-side filtering; adding, updating, or removing an item from an item list can be handled, for
 * filterable purposes, without re-rendering the entire list, but to do so we must know how the item changes
 * will affect the filter button counts; this method, specifically, is used to compute the changes to filter
 * button counts when an item is to be removed from the list
 * -- ---- -- -- --
 * sectionName:  identifies the item list in question
 * itemSelector: a CSS selector that identifies the item that is to be removed
 */
export function filterableComputeDeltasForRemovedItem(sectionName, itemSelector) {
  const returnValue = filterableInitializeSlugDeltas(sectionName);

  const itemSlugs = filterableGetItemSlugs(itemSelector);

  // the item should only match one mutex slug and that slug's count needs to be decremented
  Object.keys(returnValue).forEach(function (slug) {
    const slugDeltas = returnValue[slug];

    if (itemSlugs.includes(slug)) slugDeltas['count'] -= 1;

    if (Object.keys(slugDeltas).includes('negated') && ! itemSlugs.includes(slug)) {
      slugDeltas['negated'] -= 1;
    }
  });

  return returnValue;
}

/*
 * DEPRECATED
 * filterableCountEnabledFilterButtons
 * used to determine whether or not the filter controls should be presented to the user at all; if there aren't
 * any enabled filter buttons that will actually change the list, don't bother showing the controls
 * -- ---- -- -- --
 * sectionName: the section name for the list of interest
 */
export function filterableCountEnabledFilterButtons(sectionName) {
  const numButtons = filterableButtons(sectionName).length;
  const numDisabledButtons = filterableButtons(sectionName, filterableButtonDirectiveDisabledOnly).length;
  return numButtons - numDisabledButtons;
}

export function filterableCountUsefulFilterButtons(sectionName, pageCount) {
  var returnValue = 0;

  const buttons = filterableButtons(sectionName);
  buttons.forEach(function(button) {
    var negated = false
    const buttonCount = filterableGetButtonCount(button, negated);
    if (buttonCount > 0 && buttonCount < pageCount) {
      returnValue += 1;
    }
  });

  return returnValue;
}

/*
 * filterableGetEngagedFilters
 * used by job listable to construct params to submit to the server when sorting and paging
 * -- ---- -- -- --
 * hostContainer: a container object (usually a div) that contains no more than one filter control selector
 */
export function filterableGetEngagedFilters(hostContainer) {
  const controlSelector = filterableBuildFilterControlSelector();
  const filterControlContainer = $(hostContainer).find(controlSelector);
  const sectionName = $(filterControlContainer).data('filterable-section');
  return filterableGetEngagedFiltersSlugs(sectionName);
}

/*
 * filterableGetItemSlugs
 * gets the filter slugs associated with a specific list item
 * -- ---- -- -- --
 * itemSelector: a CSS selector that identifies a specific list item
 */
export function filterableGetItemSlugs(itemSelector) {
  var returnValue;

  // TODO there should probably be one method responsible for getting the slug string from an item
  const slugElementSelector = itemSelector + ' .' + filterableItemSlugsClassName;
  const slugString = $(slugElementSelector).data(filterableSlugsDataKey);

  if (slugString && slugString.length > 0) {
    returnValue = slugString.split(" ");
  }
  else {
    returnValue = [];
  }

  return returnValue;
}

export function filterableHideShowPageLevelControls(hide) {
  if (hide) {
    $('#filterablePageLevelControlsToggle').hide();
    $('#filterablePageLevelControlsContainer').hide();
  }
  else {
    $('#filterablePageLevelControlsToggle').show();
    $('#filterablePageLevelControlsContainer').show();
  }
}

export function filterableReportCurrentFilteringState(sectionName, generateOutsideParams) {
  const controlSelector = filterableBuildFilterControlSelector(sectionName);

  if ($(controlSelector).length > 0) {
    // there is actually something there to report about
    const slugs = filterableGetEngagedFiltersSlugs(sectionName);
    const negated = filterableGetNegatedFiltersSlugs(sectionName);

    var outside;
    if (generateOutsideParams && typeof(generateOutsideParams) === typeof(Function)) {
      outside = generateOutsideParams(sectionName);
    }
    filterableReportFilterToServer(controlSelector, sectionName, slugs, negated, outside);
  }
}

/*
 * filterableUpdateFiltersControl
 * for client-side filtering only; when one or more items in the list are added, updated, and/or removed
 * the filtering state and the set of list items that are visible may become out of sync; to address this
 * we must update the filter button counts to be consistent with the update list and then we must re-apply
 * the filters specified by the updated filter buttons
 * -- ---- -- -- --
 * sectionName: identifies the item list for which to update the filters control
 */
export function filterableUpdateFiltersControl(sectionName) {
  const controlSelector = filterableBuildFilterControlSelector(sectionName);

  if ($(controlSelector).length > 0) {
    // there actually are filter controls
    const unfilteredItemCount = filterableGetUnfilteredItemCount(controlSelector);

    const buttons = filterableButtons(sectionName);
    buttons.forEach(function(button) {
      const filter = filterableInferFilterFromButton(button);
      const slug   = filter[filterableFilterKeySlug];
      const mutex  = filter[filterableFilterKeyMutex];

      var newCount;
      var newNegatedCount;

      if (mutex) {

        /*
         * mutex buttons are easy, for each button, simply count the number of items with the corresponding
         * filter slug; there is not negated count
         */

        const slugsMutex = [slug];
        newCount = filterableCountItems(controlSelector, sectionName, slugsMutex)
      }
      else {

        /*
         * non-mutex buttons are more difficult; to find the correct count for a non-mutex button we must
         * constuct a list of slugs for the engaged filters making sure to omit the slug for the button in
         * question; then unfilteredItemCount becomes the number of items that have all of the slugs in
         * the constructed list, the button count is the number of items that were counted to find
         * unfilteredItemCount that also have the slug given by the button in question, and the negated
         * count is the number of items that were counted to find unfilteredItemCount that do not have the
         * slug given by the button
         */

        const slugsOther = filterableGetEngagedFiltersSlugs(sectionName);
        const otherEngagedSlugs = slugsOther.filter(s => s != slug);
        if (! slugsOther.includes(slug)) slugsOther.push(slug);

        const negatedSlugsOther = filterableGetNegatedFiltersSlugs(sectionName);
        const engagedNegatedSlugsOther = negatedSlugsOther.filter(s => s != slug);
        newCount = filterableCountItems(controlSelector, sectionName, slugsOther, engagedNegatedSlugsOther);

        const filteredItemCount = filterableCountItems(
          controlSelector,
          sectionName,
          otherEngagedSlugs,
          engagedNegatedSlugsOther
        );

        newNegatedCount = filteredItemCount - newCount;
      }

      filterableSetButtonCount(controlSelector, sectionName, unfilteredItemCount, button, newCount, newNegatedCount);
    }); // for each button
  }
}

/*
 * filterableUpdatePageLevelButtonCounts
 * page level filter buttons are "syntactic sugar" for the item list filter buttons; specifically, any filters
 * that are defined for all item list filter buttons are represented as page level filter buttons; at any time,
 * assuming the item list filter buttons are properly configured, the page level filter buttons can be
 * configured to match; this method does just that
 *
 * if the calling program supplies pageCount (see below), this function will return boolean indicating whether
 * or not the page level filters are useful; if the calling program does not supply pageCount the return value
 * of this function is undefined;  the page level filters are useful if there is at least one page level filter
 * that will change the content of at least one job list when engaged
 * -- ---- -- -- --
 * pageCount: if given, the total number of filterable items represented on the page
 */
export function filterableUpdatePageLevelButtonCounts(pageCount) {
  var returnValue = false;

  const pageLevelControlSelector = filterableBuildFilterControlSelector(filterablePageLevelPseudoSectionName);

  // construct count accumulators for each page-level slug
  const pageFilters = { };
  const pageButtons = filterableButtons(filterablePageLevelPseudoSectionName);
  pageButtons.forEach(function(button) {
    const pageButtonFilter = filterableInferFilterFromButton(button);
    const pageButtonSlug = pageButtonFilter[filterableFilterKeySlug];

    pageFilters[pageButtonSlug] = {
      disabled:     true,
      engaged:      true,
      negated:      false,
      count:        0,
      negatedCount: 0
    };
  });

  // accumulate counts for page level buttons by pulling counts from each list; here we are assuming that
  // the lists are mutually exclusive (IOW, no item appears in more than one list)
  var firstSection = true;
  var unfilteredItemCount = 0;
  const sections = filterableSections();
  sections.forEach(function(sectionName) {
    const sectionControlSelector = filterableBuildFilterControlSelector(sectionName);
    unfilteredItemCount += filterableGetUnfilteredItemCount(sectionControlSelector);

    const listButtons = filterableButtons(sectionName);
    listButtons.forEach(function(button) {
      const filter = filterableInferFilterFromButton(button);
      const slug   = filter[filterableFilterKeySlug];
      const mutex  = filter[filterableFilterKeyMutex];

      if (pageFilters[slug]) {
        const pageFilterInfo = pageFilters[slug];

        /*
         * suppose a filter is engaged at the page level and in list A the filter results in N > 0 list
         * items being displayed, but in list B the filter results in zero (0) list items being displayed;
         * in this case, in list A the filter will appear as engaged, but in list B the filter will
         * appear as disabled; for scenarios like this, the method must consider that a disabled item
         * list filter may effectively be treated as engaged
         */

        if (! filterableButtonIsEngaged(button) && ! filterableButtonIsDisabled(button)) {
          pageFilterInfo['engaged'] = false;
        }

        if (! filterableButtonIsDisabled(button)) {
          pageFilterInfo['disabled'] = false;
        }

        var negated = false;
        pageFilterInfo['count'] += filterableGetButtonCount(button, negated);

        if (! mutex) {

          /*
           * any difference in negated links across item lists means that the item lists will not
           * impact the corresponding negated link at the page level
           */

          if (! firstSection) {
            if (filter[filterableFilterKeyNegated] != pageFilterInfo['negated']) {
              pageFilterInfo['engaged'] = false;
              pageFilterInfo['negated'] = pageFilterInfo['negated']
            }
          }
          else {
            pageFilterInfo['negated'] = filter[filterableFilterKeyNegated];
          }

          var negated = true;
          pageFilterInfo['negatedCount'] += filterableGetButtonCount(button, negated);
        }
      }
    });

    firstSection = false;
  });

  // for each page level filter button...
  pageButtons.forEach(function(button) {
    const filter = filterableInferFilterFromButton(button);
    const slug   = filter[filterableFilterKeySlug];
    const mutex  = filter[filterableFilterKeyMutex];

    const pageFilterInfo = pageFilters[slug];

    if (! mutex) {
      var negateDirective = filterableNegateDirectiveUnnegate;
      if (pageFilterInfo['negated']) negateDirective = filterableNegateDirectiveNegate;
      filterableNegateUnnegateButton(button, negateDirective);
    }

    var engageDirective = filterableEngageDirectiveDisengage;
    if (pageFilterInfo['engaged']) engageDirective = filterableEngageDirectiveEngage;
    filterableEngageDisengageButton(button, engageDirective);

    var enable = true;
    if (pageFilterInfo['disabled']) enable = false;
    filterableEnableDisableButton(button, enable);

    // set the counts using the counts accumulated from the lists
    filterableSetButtonCount(pageLevelControlSelector, filterablePageLevelPseudoSectionName,
                             unfilteredItemCount, button, pageFilterInfo['count'], pageFilterInfo['negatedCount']);

    if (pageCount && pageFilterInfo['count'] < pageCount && pageFilterInfo['negatedCount'] < pageCount) {
      returnValue = true;
    }
  }); // each page-level button

  return returnValue;
}

export function filterableUpdateButton(sectionName, unfilteredItemCount, slug, count, negatedCount = null) {
  const controlSelector = filterableBuildFilterControlSelector(sectionName);
  const button = filterableGetButtonBySlug(sectionName, slug);
  filterableSetButtonCount(controlSelector, sectionName, unfilteredItemCount, button, count, negatedCount);
}


// -- ---- -- -- --
// helpers
// -- ---- -- -- --

/*
 * filterableAllowNegation
 * returns true if the specified filter button can be negated; returns false otherwise
 * -- ---- -- -- --
 * button:            the specified filter button
 * givenCount:        if the calling program already has the button count, it can supply it here to save
 *                    this function from having to extract the current button count from the button
 * givenNegatedCount: if the calling program already has the button negated count, it can supply it here to
 *                    save this function from having to extract the current button negated count from the
 *                    button
 */
function filterableAllowNegation(button, givenCount, givenNegatedCount) {
  var returnValue = false;

  var count = givenCount;
  if (count == null) {
    const negated = false
    count = filterableGetButtonCount(button, negated);
  }

  var negatedCount = givenNegatedCount;
  if (negatedCount == null) {
    const negated = true
    negatedCount = filterableGetButtonCount(button, negated);
  }

  if ((filterableButtonIsDisabled(button) && ! filterableButtonIsEngaged(button)) ||
      ! filterableButtonIsEngaged(button) || (count != 0 && negatedCount != 0)) {
    returnValue = true;
  }

  return returnValue;
}

/*
 * filterableApplyMutexFilters
 * captures the logic for applying mutex filters; returns a subset (possibly improper) of items; all of the
 * items in the returned subset are permitted by the mutex filters in filters; if no filters are engaged the
 * items is returned unchanged
 * -- ---- -- -- --
 * filters:   a set of filters; not all are required to be mutex filters; only the mutex filters will be
 *            applied by this method
 * items:     a set of list items to which to apply the mutex filters
 * itemClass: the HTML class name used to identify items in the subject item list(s)
 */
function filterableApplyMutexFilters(filters, items, itemClass) {
  var returnValue;

  // build a list of slug divs for which the mutually exclusive filters appear
  var slugDivSelector;
  var slugDivsForItemsToShow = [];
  const baseSlugDivSelector = '.' + filterableItemSlugsClassName;

  var anyMutexFilters = false;
  filters.forEach(function(filter) {
    if (filter[filterableFilterKeyMutex]) {
      anyMutexFilters = true;

      // for a mutually exclusive filter, a job can only show in the list if the job has the filter's slug
      const slugDivSelector = baseSlugDivSelector + '[data-' + filterableSlugsDataKey + '*="' +
                              filter[filterableFilterKeySlug] + '"]';
      slugDivsForItemsToShow = slugDivsForItemsToShow.concat($(items).find(slugDivSelector).toArray());
    }
  });

  if (anyMutexFilters) {
    returnValue = $(slugDivsForItemsToShow).closest('.' + itemClass).toArray();
  }
  else {
    returnValue = items;
  }

  return returnValue;
}

/*
 * filterableApplyOtherFilters
 * captures the logic for applying non-mutext filters; was really only separated to keep the calling program
 * simple and readable; returns a subset (possibly improper) of items; all of the items in the returned
 * subset are permitted by the non-mutex filters in filters
 * -- ---- -- -- --
 * filters:   a set of filters; not all are required to be non-mutex filters; only non-mutex filters will be
 *            applied by this method
 * items:     a set of list items to which to apply the mutex filters
 * itemClass: the HTML class name used to identify items in the subject item list(s)
 */
function filterableApplyOtherFilters(filters, items, itemClass) {
  const baseSlugDivSelector = '.' + filterableItemSlugsClassName;
  var   slugDivsForItemsToShow = $(items).find(baseSlugDivSelector).toArray();

  // remove any slug divs from the list that:
  //   1. don't have the the slug for "other" filters that have not been negated OR
  //   2. do have the slug for "other" filters that have been negated
  filters.forEach(function(filter) {
    if (! filter[filterableFilterKeyMutex]) {
      const slugDivsToRemove = [];
      slugDivsForItemsToShow.forEach(function(slugDiv) {
        if ((filter[filterableFilterKeyNegated] &&
             $(slugDiv).data(filterableSlugsDataKey).includes(filter[filterableFilterKeySlug])) ||
            (! filter[filterableFilterKeyNegated] &&
             ! $(slugDiv).data(filterableSlugsDataKey).includes(filter[filterableFilterKeySlug]))) {
          slugDivsToRemove.push(slugDiv);
        }
      });
      const work = slugDivsForItemsToShow.filter((element) => ! slugDivsToRemove.includes(element));

      // if the filter cleared the list; don't apply it; this can happen if the filter was engaged when a mutex
      // filter was changed that affected the non-mutex filter; the filter will be disabled and disengaged when
      // the button counts are updated
      //if (work.length > 0) {
        slugDivsForItemsToShow = work;
      //}
    }
  });

  return $(slugDivsForItemsToShow).closest('.' + itemClass).toArray();
}

function filterableBuildButtonSelectorCore() {
  return '.' + filterableButtonClassName;
}

/*
 * filterableBuildFilter
 * the procedure for building a "standard" representation of a filter within filterable JS
 * -- ---- -- -- --
 * slug:    the filter slug
 * mutex:   true if the filter is a mutually exclusive filter; false otherwise
 * negated: true if the filter is negated; false otherwise
 */
function filterableBuildFilter(slug, mutex, negated) {
  var returnValue = { };

  returnValue[filterableFilterKeyMutex]   = mutex;
  returnValue[filterableFilterKeyNegated] = negated;
  returnValue[filterableFilterKeySlug]    = slug;

  return returnValue;
}

/*
 * filterableBuildFilterControlSelector
 * constructs a CSS selector for the filterable filter controls
 * -- ---- -- -- --
 * sectionName: if given, the constructed CSS selector will select the filter controls that belong to the item
 *              list identified by sectionName; if not given the CSS selector will select all filterable filter
 *              controls within whatever scope the selector is applied
 */
function filterableBuildFilterControlSelector(sectionName) {
  var returnValue = '.filterable-container'

  if (sectionName) {
    returnValue += '[data-filterable-section=' + sectionName + ']';
  }

  return returnValue;
}

function filterableButtonIsDisabled(button) {
  return $(button).hasClass(filterableDisabledClassName);
}

function filterableButtonIsEngaged(button) {
  return $(button).hasClass(filterableEngagedClassName);
}

/*
 * filterableButtons
 * returns an array of filter button elements
 * -- ---- -- -- --
 * sectionName: identifies an item list; the returned array will only contain filter buttons that correspond
 *              to the identified item list
 * directive:   one of
 *
 *                filterableButtonDirectiveAll:           the returned array will contain all filter buttons
 *                filterableButtonDirectiveAllMutex:      the returned array will contain all mutually-exclusive
 *                                                        filter buttons
 *                filterableButtonDirectiveDisabledMutex: the returned array will contain only mutually-exclusive
 *                                                        filter buttons that are disabled
 *                filterableButtonDirectiveDisabledOnly:  the returned array will contain only the filter buttons
 *                                                        that have been disabled
 *                filterableButtonDirectiveEngagedOnly:   the returned array will contain only the filter buttons
 *                                                        that are engaged
 */
function filterableButtons(sectionName, directive = filterableButtonDirectiveAll) {
  const controlSelector = filterableBuildFilterControlSelector(sectionName);

  var buttonSelector = filterableBuildButtonSelectorCore();

  if (directive == filterableButtonDirectiveAllMutex ||
      directive == filterableButtonDirectiveDisabledMutex) {
    buttonSelector = '.' + filterableMutexContainerClassName + ' ' + buttonSelector;
  }

  if (directive == filterableButtonDirectiveEngagedOnly) {
    buttonSelector += '.' + filterableEngagedClassName;
  }
  else if (directive == filterableButtonDirectiveDisabledOnly ||
           directive == filterableButtonDirectiveDisabledMutex) {
    buttonSelector += '.' + filterableDisabledClassName;
  }

  return $(controlSelector).find(buttonSelector).toArray();
}

/*
 * filterableCountItems
 * returns the number of list items in the specified item list that match the specified filter slugs
 * -- ---- -- -- --
 * controlSelector: selects the filtering controls (convenience)
 * sectionName:     specifies the item list of interest
 * slugs:           specifies which filter buttons are engaged
 * negatedSlugs:    specifies which non-mutex filter buttons are negated
 */
function filterableCountItems(controlSelector, sectionName, slugs, negatedSlugs) {
  var returnValue = 0;

  const itemClass = filterableGetListItemClass(controlSelector);
  var items = filterableGetListItems(controlSelector, sectionName);

  const slugDivSelector = '.' + filterableItemSlugsClassName;
  const slugDivs = $(items).find(slugDivSelector).toArray();
  slugDivs.forEach(function(slugDiv) {
    const itemSlugs = $(slugDiv).data(filterableSlugsDataKey);
    var numFoundSlugs = 0;
    slugs.forEach(function(slug) {
      var slugIsNegated = false;
      if (negatedSlugs) slugIsNegated = negatedSlugs.includes(slug)
      if ((itemSlugs.includes(slug) && ! slugIsNegated) || (! itemSlugs.includes(slug) && slugIsNegated)) {
        numFoundSlugs++;
      }
    });

    if (slugs.length == numFoundSlugs) returnValue++;
  });

  return returnValue;
}

/*
 * filterableDisengageButtons
 * disengages filter buttons for a specified section; can be limited to a specified set of slugs
 * -- ---- -- -- --
 * sectionName: identifies the section for which buttons are to be disengaged
 * slugs:       if given, specifies a set of slugs for which buttons should be disengaged; if not given
 *              all filter buttons will be disengaged
 */
function filterableDisengageButtons(sectionName, slugs) {
  const buttons = filterableButtons(sectionName);
  buttons.forEach(function(button) {
    const filter = filterableInferFilterFromButton(button);
    const slug = filter[filterableFilterKeySlug];

    if (! slugs || (slugs && slugs.includes(slug))) {
      filterableEngageDisengageButton(button, filterableEngageDirectiveDisengage);
    }
  });
}

function filterableEnableDisableButton(button, enable) {
  if (! enable) {
    if (filterableButtonIsEngaged(button)) {
      filterableEngageDisengageButton(button, filterableEngageDirectiveDisengage);
    }
    $(button).addClass(filterableDisabledClassName);
  }
  else {
    $(button).removeClass(filterableDisabledClassName);
  }
}

function filterableEngageDisengageButton(button, directive = filterableEngageDirectiveEngage) {
  const currentlyEngaged = filterableButtonIsEngaged(button);

  var engage = true;
  if ((directive == filterableEngageDirectiveDisengage) ||
      (directive == filterableEngageDirectiveToggle && currentlyEngaged)) {
    engage = false;
  }

  if (! engage) {
    $(button).removeClass(filterableEngagedClassName);

    // we just disengaged, check to see if the button should be disabled
    var negated = false;
    const count = filterableGetButtonCount(button, negated);

    negated = true;
    const negatedCount = filterableGetButtonCount(button, negated);

    if (count == 0 || negatedCount == 0) {
      const enable = false;
      filterableEnableDisableButton(button, enable);
    }
  }
  else {
    $(button).addClass(filterableEngagedClassName);
  }
}

/*
 * filterableFilterSide
 * returns the "side" value for the specified section;  the side value indicates whether fitlering for
 * the specified section should be handled client-side or server-side
 * -- ---- -- -- --
 * sectionName: identifies the section of interest
 */
function filterableFilterSide(sectionName) {
  const controlSelector = filterableBuildFilterControlSelector(sectionName);
  return $(controlSelector).data('filterable-side');
}

function filterableGetButtonBySlug(sectionName, slug) {
  const sectionControlsSelector = filterableBuildFilterControlSelector(sectionName);
  const buttonSelector = filterableBuildButtonSelectorCore() +
                         '[data-' + filterableButtonSlugDataKey + '=' + slug + ']';
  return $(sectionControlsSelector).find(buttonSelector);
}

/*
 * filterableGetButtonCount
 * returns the count displayed on the given button
 * -- ---- -- -- --
 * button:  the given button
 * negated: true if the function should return the button's negated count; false if the function should return
 *          the button's non-negated count
 */
function filterableGetButtonCount(button, negated = false) {
  const countContainer = filterableGetButtonCountContainer(button, negated);
  return parseInt($(countContainer).text());
}

function filterableGetButtonCountContainer(button, negated = false) {
  var countContainerSelector = '.' + filterableCountClassName;
  if (negated) {
    countContainerSelector += '.' + filterableNegatedClassName;
  }

  return $(button).find(countContainerSelector)[0];
}

/*
 * filterableGetEngagedFiltersCore
 * constructs a list of hashes, each of which describes a filter that is currently engaged; see
 * filterableInferFilterFromButton for a description of the hashes
 * -- ---- -- -- --
 * sectionName:   identifies the item list forwhich the engaged filters are to be returned
 * simulateEmpty: false if the function should only return filters if they are explicitly engaged; true
 *                if the function should return filters that are equivalent to "no filters" in the event
 *                that no filters are explicity engaged
 */
function filterableGetEngagedFiltersCore(sectionName, simulateEmpty = false) {
  var filters = []

  var buttons = filterableButtons(sectionName, filterableButtonDirectiveEngagedOnly);

  if (buttons.length == 0 && simulateEmpty) {
    var buttons = filterableButtons(sectionName, filterableButtonDirectiveAllMutex);
  }

  buttons.forEach(function(button) {
    filters.push(filterableInferFilterFromButton(button));
  });

  return filters;
}

function filterableGetEngagedFiltersSlugs(sectionName) {
  const returnValue = [];

  const engagedFilters = filterableGetEngagedFiltersCore(sectionName);

  engagedFilters.forEach(function(filter) {
    const f = filter[filterableFilterKeySlug];
    if (f) returnValue.push(f);
  });

  return returnValue;
}

/*
 * filterableGetItemListSlugs
 * gets the filter slugs associated with a specific item list; returns the slugs in an object consisting of
 * two arrays, one for mutex filter slugs and one for other filter slugs
 * -- ---- -- -- --
 * sectionName: identifies the item list for which to acquire item slugs
 */
function filterableGetItemListSlugs(sectionName) {
  const returnValue = {
    mutex: [],
    other: []
  };


  const buttons = filterableButtons(sectionName);
  buttons.forEach(function(button) {
    const filter = filterableInferFilterFromButton(button);
    const slug   = filter[filterableFilterKeySlug];
    const mutex  = filter[filterableFilterKeyMutex];

    if (mutex) {
      returnValue['mutex'].push(slug);
    }
    else {
      returnValue['other'].push(slug);
    }
  });

  return returnValue;
}

/*
 * filterableGetListItemClass
 * returns the HTML class that should be used to select items from the list associated with the filter
 * controls specified
 * -- ---- -- -- --
 * controlSelector: used to select the subject filter controls
 */
function filterableGetListItemClass(controlSelector) {
  return $(controlSelector).data('filterable-item');
}

function filterableGetListItems(controlSelector, sectionName, visible = false) {
  // itemListClass is the class that should be used, in conjunction with sectionName, to select the subject list
  const itemListClass = $(controlSelector).data('filterable-list');

  // itemClass is the class that should be used to select items in the list
  const itemClass = filterableGetListItemClass(controlSelector);

  // itemListSectionKey is the data key where we can find the section data key used in the subject item list
  const itemListSectionKey =
    $(controlSelector).data('filterable-section-key');

  // get a handle to the list that corresponds to the tapped filter controls
  const itemListSelector = '.' + itemListClass + '[data-' + itemListSectionKey + '=' + sectionName + ']';
  const itemList = $(itemListSelector)[0];

  var finalSelector = '.' + itemClass;
  if (visible) {
    finalSelector += ':visible';
  }

  return $(itemList).find(finalSelector);
}

/*
 * filterableGetNegateLinks
 * every non-mutex filter button has two negate links, one for when the button is negated and one for when
 * the button is not negated; this function returns an array containing both links if the given button is a
 * non-mutex filter button
 * -- ---- -- -- --
 * button: the given button
 */
function filterableGetNegateLinks(button) {
  return $(button).find('.' + filterableNegateLinkClassName).toArray();
}

function filterableGetNegatedFiltersSlugs(sectionName) {
  const returnValue = [];

  const buttons = filterableButtons(sectionName);
  buttons.forEach(function(button) {
    const filter = filterableInferFilterFromButton(button);
    if (! filter[filterableFilterKeyMutex] && filter[filterableFilterKeyNegated]) {
      returnValue.push(filter[filterableFilterKeySlug]);
    }
  });

  return returnValue;
}

function filterableGetUnfilteredItemCount(controlSelector) {
  return parseInt($(controlSelector).data(filterableItemCountDataKey));
}

export function filterableIncrementUnfilteredItemCount(sectionName) {
  const controlSelector = filterableBuildFilterControlSelector(sectionName);
  const oldCount = filterableGetUnfilteredItemCount(controlSelector);
  $(controlSelector).data(filterableItemCountDataKey, (oldCount + 1).toString());
}

export function filterableDecrementUnfilteredItemCount(sectionName) {
  const controlSelector = filterableBuildFilterControlSelector(sectionName);
  const oldCount = filterableGetUnfilteredItemCount(controlSelector);
  $(controlSelector).data(filterableItemCountDataKey, (oldCount - 1).toString());
}

/*
 * filterableHandleClientSide
 * returns true if filtering for the specified section should be handled client-side; returns false if
 * filtering for the specified section should be handled server-side
 * -- ---- -- -- --
 * sectionName: identifies the section of interest
 */
function filterableHandleClientSide(sectionName) {
  var returnValue = false;

  const filterSide = filterableFilterSide(sectionName);
  if (filterSide == 'client') {
    returnValue = true;
  }

  return returnValue;
}

/*
 * filterableHandleFilterChange
 * handles a change to the set of engaged filters
 * -- ---- -- -- --
 * filterButton:          the button that corresponds to the filter that should be changed
 * engageDirective:       indicates if the subject filter is to be engaged or disengaged
 * generateOutsideParams: this should be a function that accepts a section name (to identify the subject item
 *                        list) and either returns "undefined" OR an object that can be used as params to send
 *                        to the server
 */
function filterableHandleFilterChange(filterButton, engageDirective, generateOutsideParams) {

  if (! filterableButtonIsDisabled(filterButton)) {
    filterableEngageDisengageButton(filterButton, engageDirective);

    const filterControlContainer = $(filterButton).closest('.filterable-container');
    const sectionName = $(filterControlContainer).data('filterable-section');

    if (filterableHandleClientSide(sectionName)) {
      const sectionNames = [];

      filterableApplyFilters(sectionName);
      filterableUpdateCounts(sectionName);
      filterableUpdatePageLevelButtonCounts();
    }

    filterableReportCurrentFilteringState(sectionName, generateOutsideParams)
  }
}

/*
 * filterableHandleFilterTap
 * handles a tap on any filter button
 * -- ---- -- -- --
 * event:                 the subject event
 * generateOutsideParams: this should be a function that accepts a section name (to identify the subject item
 *                        list) and either returns "undefined" OR an object that can be used as params to send
 *                        to the server
 */
function filterableHandleFilterTap(event, generateOutsideParams) {
  const tappedButton = filterablePreHandleButtonTap(event);
  filterableHandleFilterChange(tappedButton, filterableEngageDirectiveToggle, generateOutsideParams);
}

function filterableHandleNegationChange(filterButton, engageDirective, generateOutsideParams) {
  if (filterableAllowNegation(filterButton)) {
    filterableNegateUnnegateButton(filterButton, filterableNegateDirectiveToggle);
          
    if (filterableButtonIsEngaged(filterButton)) {
      // toggling the negate state will affect the filtered list
      const filterControlContainer = $(filterButton).closest('.filterable-container');
      const sectionName = $(filterControlContainer).data('filterable-section');

      if (filterableHandleClientSide(sectionName)) {
        filterableApplyFilters(sectionName);
        filterableUpdateCounts(sectionName);
        filterableUpdatePageLevelButtonCounts();
      }

      filterableReportCurrentFilteringState(sectionName, generateOutsideParams);
    }
  }
}

function filterableHandleNegateLinkTap(event, button, generateOutsideParams) {
  pmappPreventDefaults(event);
  filterableHandleNegationChange(button, filterableNegateDirectiveToggle, generateOutsideParams);
}

/*
 * filterableHandlePageLevelFilterTap
 * handler for "tap" events on page-level filter buttons
 * -- ---- -- -- --
 * event:                 the "tap" event itself
 * generateOutsideParams: this should be a function that accepts a section name (to identify the subject item
 *                        list) and either returns "undefined" OR an object that can be used as params to send
 *                        to the server
 */
function filterableHandlePageLevelFilterTap(event, generateOutsideParams) {
  const tappedButton = filterablePreHandleButtonTap(event);
  filterableEngageDisengageButton(tappedButton, filterableEngageDirectiveToggle);

  const tappedFilter  = filterableInferFilterFromButton(tappedButton);
  const tappedSlug    = tappedFilter[filterableFilterKeySlug];
  const tappedIsMutex = tappedFilter[filterableFilterKeyMutex];

  // determine if the tapped button is now engaged or disengaged and set up engageDirective to reflect that
  var negateDirective;
  var engageDirective = filterableEngageDirectiveDisengage;
  if (filterableButtonIsEngaged(tappedButton)) {
    engageDirective = filterableEngageDirectiveEngage;

    // determine if the tapped button is now negated or not and set up negateDirective to reflect that
    if (! tappedIsMutex) {
      var negateDirective = filterableNegateDirectiveUnnegate;
      if (tappedFilter[filterableFilterKeyNegated]) {
        negateDirective = filterableNegateDirectiveNegate;
      }
    }
  }

  // for each list on the page...
  const sections = filterableSections();
  sections.forEach(function(sectionName) {
    // find the list filter button with the same slug as the page filter button
    const filterButton = filterableGetButtonBySlug(sectionName, tappedSlug);

    if (negateDirective) {
      // we may need to negate or un-negate the button
      const filterButtonFilter = filterableInferFilterFromButton(filterButton);
      const filterButtonIsNegated = filterButtonFilter[filterableFilterKeyNegated];

      if ((negateDirective == filterableNegateDirectiveNegate && ! filterButtonIsNegated) ||
          (negateDirective != filterableNegateDirectiveNegate && filterButtonIsNegated)) {
        filterableHandleNegationChange(filterButton, negateDirective, generateOutsideParams);
      }
    }

    const isEngaged = filterableButtonIsEngaged(filterButton);
    if ((engageDirective == filterableEngageDirectiveEngage && ! isEngaged) ||
        (engageDirective != filterableEngageDirectiveEngage && isEngaged)) {
      filterableHandleFilterChange(filterButton, engageDirective, generateOutsideParams);
    }
  }); // each section

  // now that all of the list's filter buttons are where they should be we can reset the page
  // level filters accordingly
  filterableUpdatePageLevelButtonCounts();
}

/*
 * filterableHandlePageLevelNegateLinkTap
 * handler for "tap" events on negate links in page-level, non-mutex filter buttons
 * -- ---- -- -- --
 * event:                 the "tap" event itself
 * generateOutsideParams: this should be a function that accepts a section name (to identify the subject item
 *                        list) and either returns "undefined" OR an object that can be used as params to send
 *                        to the server
 */
function filterableHandlePageLevelNegateLinkTap(event, button, generateOutsideParams) {
  pmappPreventDefaults(event);

  if (filterableAllowNegation(button)) {
    // the page level button will allow negation, toggle the negation state of the page level button
    filterableNegateUnnegateButton(button, filterableNegateDirectiveToggle);

    if (filterableButtonIsEngaged(button)) {
      // the page level button is engaged, toggle the corresponding list-level buttons
      const buttonFilter = filterableInferFilterFromButton(button);
      const buttonSlug = buttonFilter[filterableFilterKeySlug];

      // for each list on the page...
      const sections = filterableSections();
      sections.forEach(function(sectionName) {
        // find the list filter button with the same slug as the page filter button
        const filterButton = filterableGetButtonBySlug(sectionName, buttonSlug);
        filterableHandleNegationChange(filterButton, filterableNegateDirectiveToggle, generateOutsideParams);
      }); // each section

      filterableUpdatePageLevelButtonCounts();
    } // if the page level button is engaged
  } // if the page level button can be negated
}

/*
 * filterableHandlePageLevelResetLinkTap
 * handler for "tap" events on the reset links in page-level filter controls
 * -- ---- -- -- --
 * event:                 the "tap" event itself
 * generateOutsideParams: this should be a function that accepts a section name (to identify the subject item
 *                        list) and either returns "undefined" OR an object that can be used as params to send
 *                        to the server
 */
function filterableHandlePageLevelResetLinkTap(event, generateOutsideParams) {
  pmappPreventDefaults(event);

  const slugs = [];
  // for each page level filter button...
  const pageButtons = filterableButtons(filterablePageLevelPseudoSectionName);
  pageButtons.forEach(function(button) {
    const filter = filterableInferFilterFromButton(button);
    slugs.push(filter[filterableFilterKeySlug]);
  });

  // for each list on the page...
  const sections = filterableSections();
  sections.forEach(function(sectionName) {
    // reset the list filter buttons, but limit the reset to the slugs extracted from the page level buttons
    filterableClear(sectionName, slugs, generateOutsideParams);
  });

  // reset the page-level fitler buttons
  filterableDisengageButtons(filterablePageLevelPseudoSectionName, slugs);
  filterableUnnegateButtons(filterablePageLevelPseudoSectionName, slugs);
}

function filterableHideShowNegateLinks(button, hide) {
  const negateLinkContainerSelector = '.' + filterableNegateLinkContainerClassName;

  if (hide) {
    $(button).find(negateLinkContainerSelector).hide();
  }
  else {
    $(button).find(negateLinkContainerSelector).show();
  }
}

/*
 * filterableInferFilterFromButton
 * each button corresponds to an item list filter; given a button, this method returns a hash that describes
 * the filter to which the button corresponds; the returned has has the form:
 *
 *   {
 *     filterableFilterKeyMutex:   true if the filter is a mutually exclusive filter; false otherwise
 *     filterableFilterKeyNegated: only defined if the filter is non-mutex; true if the filter is negated;
                                   false otherwise
 *     filterableFilterKeySlug:    the filter slug
 *   }
 *
 * -- ---- -- -- --
 * button: the given button
 */
function filterableInferFilterFromButton(button) {
  var filterSlug = $(button).data(filterableButtonSlugDataKey);

  var filterMutex = true;
  var filterNegated = false;
  if (filterableGetNegateLinks(button).length > 0) {
    filterMutex = false;
    filterNegated = $(button).hasClass(filterableNegatedClassName);
  }

  return filterableBuildFilter(filterSlug, filterMutex, filterNegated);
}

/*
 * filterableInitializeSlugDeltas
 * used to support server-side filtering methods for updating filter button counts when the item list
 * is modified, but not entirely redrawn
 * -- ---- -- -- --
 * sectionName: identifies the subject item list
 */
function filterableInitializeSlugDeltas(sectionName) {
  const returnValue = { };

  const listSlugs  = filterableGetItemListSlugs(sectionName);
  const mutexSlugs = listSlugs['mutex'];
  const otherSlugs = listSlugs['other'];

  mutexSlugs.forEach(function (slug) {
    returnValue[slug] = {
      count: 0
    }
  });

  otherSlugs.forEach(function (slug) {
    returnValue[slug] = {
      count:   0,
      negated: 0
    }
  });

  return returnValue;
}

function filterableNegateUnnegateButton(button, directive) {
  if (directive == filterableNegateDirectiveNegate) {
    $(button).addClass(filterableNegatedClassName);
  }
  else if (directive == filterableNegateDirectiveUnnegate) {
    $(button).removeClass(filterableNegatedClassName);
  }
  else {
    $(button).toggleClass(filterableNegatedClassName);
  }
}

/*
 * filterablePreHandleButtonTap
 * we have four separate handlers for filter button tap events; this method encapsulates the peramble to those
 * handlers that extracted the tapped button's link from the event itself
 * -- ---- -- -- --
 * event: a filter button tap event
 */
function filterablePreHandleButtonTap(event) {
  pmappPreventDefaults(event);

  var somePartOfAFilterButton = event.target || event.srcElement;
  var tappedButton = $(somePartOfAFilterButton).closest(filterableBuildButtonSelectorCore());

  return tappedButton;
}

function filterableProportionFilterButtons(sectionName, container) {
  const buttons = filterableButtons(sectionName);

  const mutexButtons = filterableButtons(sectionName, filterableButtonDirectiveAllMutex);
  const mutexDisabledButtons = filterableButtons(sectionName, filterableButtonDirectiveDisabledMutex);

  const numMutexFilterButtons = mutexButtons.length;
  const mutexContainer = $(container).find('.filterable-mutex-container');
  if (numMutexFilterButtons > 0) {
    $(mutexContainer).css('flex-grow', numMutexFilterButtons);
    $(mutexContainer).show();
  }
  else {
    $(mutexContainer).hide();
  }

  const numOtherFilterButtons = buttons.length - mutexButtons.length;
  const otherContainer = $(container).find('.filterable-other-container');
  if (numOtherFilterButtons > 0) {
    $(otherContainer).css('flex-grow', numOtherFilterButtons);
    $(otherContainer).show();
  }
  else {
    $(otherContainer).hide();
  }

}

/*
 * filterableReportFilterToServer
 * reports filtering to the server; for client-side filtering this allows the server to react to the filtering
 * in ways that are not part of the fitlerable functionality; for server-side filtering the server's reaction
 * also includes the filterable behavior
 * -- ---- -- -- --
 * filterControlContainer: a non-jQuery handle to the filterable buttons container
 * sectionName:            the section name of the item list
 * slugs:                  a list of slugs indicating which filters are engaged; null or empty if not filtering
 * negated:                a list of slugs indicating which filters are negated; this need to be a subset of
 *                         slugs as a filter can be negated without being engaged
 * outside:                if given, an object containing outside parameters that should be sent along with the
 *                         filtering parameters
 */
function filterableReportFilterToServer(filterControlContainer, sectionName, slugs, negated, outside) {
  var filterUrl  = $(filterControlContainer).data('filterable-url');
  var filterSide = filterableFilterSide(sectionName);

  // unpack any data that was directly supplied by the rootable controller
  var params = { section: sectionName,
                 slugs:   slugs,
                 negated: negated,
                 side:    filterSide }

  if (outside) params = Object.assign(params, outside);

  if (! filterableHandleClientSide(sectionName)) {
    showLoadingOverlay();
  }

  // if the item list's hosting controller enabled filtering, but does not want to be notified when filtering
  // changes, the url data value will be set to "true"
  if (filterUrl != "true") {
    $.ajax({
      data:  params,
      type:  'POST',
      url:   filterUrl,
      async: true
    });
  }
}

/*
 * filterableSections
 * returns an array of item lists (identified by section names) on the current page
 */
function filterableSections() {
  const returnValue = [];

  const sectionControlsSelector = filterableBuildFilterControlSelector();
  $(sectionControlsSelector).each(function(index, sectionControls) {
    const sectionName = $(sectionControls).data(filterableSectionDataKey);
    if (sectionName != filterablePageLevelPseudoSectionName) {
      returnValue.push(sectionName);
    }
  });

  return returnValue;
}

/*
 * filterableSetButtonCount
 * changes the count(s) that may appear on filter buttons; as a side effect this function will disable
 * any buttons that will not actually provide any filtering
 * -- ---- -- -- --
 * controlSelector:     used in conjunction with sectionName to access the item list to which the subject
 *                      button pertains
 * sectionName:         used in conjunction with controlSelector to access the item list to which the subject
 *                      button pertains
 * unfilteredItemCount: the number of items in the item list identified by sectionName when unfiltered
 * button:              the subject button
 * count:               the (non-negated) count that should be displayed on the button
 * negatedCount:        the negated count that should be displayed on the button, if the button corresponds to
 *                      a non-mutex filter that has been negated
 */
function filterableSetButtonCount(controlSelector, sectionName, unfilteredItemCount, button, count, negatedCount) {
  const countSelector = '.' + filterableCountClassName;
  $(button).find(countSelector).text(count.toString());

  if (filterableGetNegateLinks(button).length > 0) {
    // non-mutex filter button
    const negatedCountSelector = countSelector + '.' + filterableNegatedClassName;
    $(button).find(negatedCountSelector).text(negatedCount.toString());
  }

  filterableSetButtonState(controlSelector, sectionName, unfilteredItemCount, button, count, negatedCount);
}

/*
 * filterableSetButtonState
 * configures the given filter button's state based on the supplied values; for the purposes of this
 * function, a filter button's state includes whether or not the button is enabled, and for non-mutex
 * filters, whether or not the negate link is showing
 * -- ---- -- -- --
 * controlSelector:     identifies the set of filter controls within which the subject button exists
 * sectionName:         identifies the item list to which the subject button belongs
 * unfilteredItemCount: the number of items in the item list identified by sectionName when unfiltered; this is
 *                      needed to properly set the state of mutex buttons; it cannot be determined from the page
 *                      if the itme list is paging (server-side filtering)
 * button:              the subject button
 * givenCount:          if the calling program already has the button's count, it can be supplied here
 * givenNegatedCount:   if the calling program already has the button's negated count, it can be supplied here
 */
function filterableSetButtonState(controlSelector, sectionName, unfilteredItemCount, button,
                                  givenCount, givenNegatedCount) {
  var count = givenCount;
  if (! count) count = filterableGetButtonCount(button);

  var enable = false;
  if (filterableGetNegateLinks(button).length > 0) {
    //enable = filterableButtonIsEngaged(button);
    var negatedCount = givenNegatedCount;
    if (! negatedCount) {
      const negated = true;
      negatedCount = filterableGetButtonCount(button, negated);
    }

    // non-mutex filter button
    if (count != 0 && negatedCount != 0) {
      enable = true;
    }

    // if button is engaged and the "toggle count" is 0, then we need to hide the negate link
    var hide = false;
    if (! filterableAllowNegation(button, count, negatedCount)) hide = true;
    filterableHideShowNegateLinks(button, hide);
  }
  else {
    // mutex filter button, only disable if count is 0
    if (count > 0 && count < unfilteredItemCount) {
      enable = true;
    }
  }

  filterableEnableDisableButton(button, enable);

  // if the button is negated, but negatedCount was not supplied, leave the visibility of the negate link unchanged
}

function filterableSetupSlideoutEventHandlers() {
  const minimizedClassName = 'minimized';
  const minimizedContainerHeight = filterableMinPageButtonHeight + 2; // pixels

  // event handler to open the page level controls slideout
  $('#' + filterablePageControlsToggleId + ' a').click(function(event) {
    pmappPreventDefaults(event);

    // show the slideout (see CSS for an explanation as to why this does not use show())
    $('#' + filterablePageControlsContainerId).height(filterablePageSlideoutHeight);

    // slide the slideout...well...out
    $('#' + filterablePageControlsContainerId + ' .' + filterablePageSlideoutClassName).css({ top: "0px" });

    // hide the toggle link; the slideout is hidden by clicking the close (red X) link icon in the top left
    // corner of the slideout (see below)
    $('#' + filterablePageControlsToggleId).hide();
  });

  // event handler to close the page level controls slideout
  $('a#' + filterablePageControlsHideLinkId).click(function(event) {
    pmappPreventDefaults(event);

    // slide the slideout back in
    $('#' + filterablePageControlsContainerId + ' .' + filterablePageSlideoutClassName).css(
      { top: '-' + filterablePageSlideoutHeight }
    );

    // hide the slideout (see CSS for an explanation as to why this does not use hide())
    $('#' + filterablePageControlsContainerId).height('0px');

    // the slideout should never be minimized when it is opened, so un-minimize it if it is minimized
    $('#' + filterablePageControlsContainerId + ' .' + filterablePageSlideoutClassName).removeClass(
      minimizedClassName
    );

    // make sure the min/max icon link is showing min
    $('a#' + filterablePageControlsResizeLinkId).html("<i class='fas fa-angle-double-up'></i>");

    // show the toggle link
    $('#' + filterablePageControlsToggleId).show();
  });

  // event handler to minimize the page level controls slideout
  $('a#' + filterablePageControlsResizeLinkId).click(function(event) {
    pmappPreventDefaults(event);

    if ($('#' + filterablePageControlsContainerId).height() > minimizedContainerHeight) {
      // slideout is not minimized...

      // style it as minimized
      $('#' + filterablePageControlsContainerId + ' .' + filterablePageSlideoutClassName).addClass(
        minimizedClassName
      );

      // set the minimized size
      $('#' + filterablePageControlsContainerId).height(minimizedContainerHeight + 'px');

      // change the resize link to max
      $('a#' + filterablePageControlsResizeLinkId).html("<i class='fas fa-angle-double-down'></i>");
    }
    else {
      // slideout is minimized...

      // set to full size
      $('#' + filterablePageControlsContainerId).height(filterablePageSlideoutHeight);

      // style it as NOT minimized
      $('#' + filterablePageControlsContainerId + ' .' + filterablePageSlideoutClassName).removeClass(
        minimizedClassName
      );

      // change the resize link to min
      $('a#' + filterablePageControlsResizeLinkId).html("<i class='fas fa-angle-double-up'></i>");
    }
  });
}

/*
 * filterableUnnegateButtons
 * ensures that the filter buttons in an item list are not negated
 * -- ---- -- -- --
 * sectionName: identifies the item list of interest
 * slugs:       if given, this method will only consider the filter buttons associated with the slugs in
 *              this array; if not given, this method will ensure tha all buttons for non-mutext filters
 *              are not negated
 */
function filterableUnnegateButtons(sectionName, slugs) {
  const buttons = filterableButtons(sectionName);
  buttons.forEach(function(button) {
    const filter = filterableInferFilterFromButton(button);
    const slug = filter[filterableFilterKeySlug];

    if (! slugs || (slugs && slugs.includes(slug))) {
      filterableNegateUnnegateButton(button, filterableNegateDirectiveUnnegate);
    }
  });
}

/*
 * filterableUpdateCounts
 * updates the counts displayed on all filter buttons associated with the specified item list
 * -- ---- -- -- --
 * sectionName:  identifies the item list for which counts are to be updated
 */
function filterableUpdateCounts(sectionName) {
  const controlSelector = filterableBuildFilterControlSelector(sectionName);
  const unfilteredItemCount = filterableGetUnfilteredItemCount(controlSelector);
  var unfilteredItems = filterableGetListItems(controlSelector, sectionName);
  const itemClass = filterableGetListItemClass(controlSelector);

  const filters = filterableGetEngagedFiltersCore(sectionName);
  const afterMutexItems = filterableApplyMutexFilters(filters, unfilteredItems, itemClass);

  const buttons = filterableButtons(sectionName);
  buttons.forEach(function(button) {
    const filter = filterableInferFilterFromButton(button);

    // mutex filter button counts don't change, only the other button counts change
    if (! filter[filterableFilterKeyMutex]) {
      const slug = filter[filterableFilterKeySlug];
      const useFilters = [];
      filters.forEach(function(f) {
        if (f[filterableFilterKeySlug] != slug) useFilters.push(f);
      });

      const mutex = false;
      const negated = false;
      const useFilter = filterableBuildFilter(slug, mutex, negated);
      useFilters.push(useFilter);
      var items = filterableApplyOtherFilters(useFilters, afterMutexItems, itemClass);
      const count = $(items).length;

      useFilter[filterableFilterKeyNegated] = true;
      items = filterableApplyOtherFilters(useFilters, afterMutexItems, itemClass);
      const negatedCount = $(items).length;

      filterableSetButtonCount(controlSelector, sectionName, unfilteredItemCount, button, count, negatedCount);
    }
  });
}

function filterableUpdateButtonStates(sectionName) {
  const controlSelector = filterableBuildFilterControlSelector(sectionName);
  const unfilteredItemCount = filterableGetUnfilteredItemCount(controlSelector);
  const buttons = filterableButtons(sectionName);
  buttons.forEach(function(button) {
    filterableSetButtonState(controlSelector, sectionName, unfilteredItemCount, button);
  });
}

/*
 * PMApp::item_lists
 * JS for item lists (core of TaskListable and JobListable)
 */

import "jquery-ui/ui/widgets/sortable"

import { pmappPreventDefaults } from './pmapp.js.erb';


// -- ---- -- -- --
// SHUFFLEABLE (jquery-sortable) IMPLEMENTATION
// -- ---- -- -- --

/*
 * itemListsSetupMoveEventHandlers
 * configures the event handlers for the "Move up" and "Move down" actions
 * -- ---- -- -- --
 * selector: use this to select the actions from a subset of items
 */
export function itemListsSetupMoveEventHandlers(selector) {
  $(selector).find('.action-move-down').click(function(event) {
    itemListsHandleMove(event, false);
  });

  $(selector).find('.action-move-up').click(function(event) {
    itemListsHandleMove(event, true);
  });
}

/*
 * itemListsDisableShuffling
 * effectively removes shuffling capability from a item list
 * -- ---- -- -- --
 * itemListId: the HTML ID of the item list for which shuffling should be disabled
 */
export function itemListsDisableShuffling(itemListId) {
  var itemListSelector = '#' + itemListId;
  $(itemListSelector).addClass('disabled');
  $(itemListSelector).sortable('disable');
}

/*
 * itemListsEnableShuffling
 * counteracts the effect of the itemListsDisableShuffling function; this function will not enable
 * shuffling unless the item list in question has been initialized as shuffleable
 * -- ---- -- -- --
 * itemListId: the HTML ID of the item list for which shuffling should be enabled
 */
export function itemListsEnableShuffling(itemListId) {
  var itemListSelector = '#' + itemListId;
  $(itemListSelector).removeClass('disabled');
  $(itemListSelector).sortable('enable');
}

/*
 * itemListsIsShuffleable
 * returns true if the given item list has been initialized as shuffleable; returns false otherwise
 * -- ---- -- -- --
 * itemList: a handle to a item list (the given item list)
 */
export function itemListsIsShuffleable(itemList) {
  var shuffleable = false;

  if ($(itemList).data('shuffle-url')) {
    shuffleable = true;
  }
  
  return shuffleable;
}

export function itemListsResetZIndexesForSection(sectionName) {
  const itemList = $(itemListsBuildListSelector(sectionName))[0];
  if (itemList) {
    itemListsResetZIndexes(itemList);
  }
}

/*
 * itemListsSetZIndexes
 * use this function to set/initialize the Z indexes for all item lists on a page
 */
export function itemListsSetZIndexes() {
  $('.item-list').each(function(index, itemList) {
    itemListsResetZIndexes(itemList);
  });
}

export function itemListsBuildListSelector(sectionName) {
  return '.item-list' +
         '[data-section="' + sectionName + '"]';
}

// event handlers -- ---- -- -- --

/*
 * itemListsHandleMove
 * handle a user tapping a "Move up" or "Move down" action
 * -- ---- -- -- --
 * event: the event generated by the click
 * up:    true if a "Move up" was tapped; false if a "Move down" was tapped
 */
function itemListsHandleMove(event, up) {
  event = event || window.event
  pmappPreventDefaults(event);

  var icon = event.target || event.srcElement;
  var clickedLink = $(icon).closest('a');

  // hide the menu
  $(clickedLink).closest('.dropdown').dropdown('hide');

  var itemList = $(clickedLink).closest('.item-list');

  var movingItem = $(clickedLink).closest('.shuffleable-list-item');
  var movingItemId = itemListsGetMoveItemId(movingItem);
  var ordering = itemListsGetShuffleableOrdering(itemList);
  var movingIndex = ordering.indexOf(movingItemId.idString);

  if (up) {
    itemListsHandleMoveUp(itemList, movingItemId, ordering, movingIndex);
  }
  else {
    itemListsHandleMoveDown(itemList, movingItemId, ordering, movingIndex);
  }

  itemListsResetZIndexes(itemList);
}

// private

/*
 * itemListsGetShuffleableOrdering
 * builds the current ordering of a shuffleable item list
 * -- ---- -- -- --
 * itemList: the shuffleable item list in question
 */
function itemListsGetShuffleableOrdering(itemList) {
  return $(itemList).sortable(
    'toArray',
    { attribute: 'data-item-id' }
  );
}

/*
 * itemListsGetMoveItemId
 * helper for extracting an item ID from the information stashed on the views
 * -- ---- -- -- --
 * movingItem: a handle to the DOM representation of the moving item
 */
function itemListsGetMoveItemId(movingItem) {
  var movingItemId = movingItem.data("item-id");
  var movingItemIdString = movingItemId.toString();

  return {
    id: movingItemId,
    idString: movingItemIdString
  }
}

/*
 * itemListsInitializeItemListAsShuffleable
 * -- ---- -- -- --
 * itemList: a handle to the item list that needs to be shuffleable
 */
export function itemListsInitializeItemListAsShuffleable(itemList) {

  $(itemList).sortable({
    axis:                 'y', 
    containment:          itemList,
    cursor:               'move',
    disabled:             false,
    items:                '.shuffleable-list-item',
    helper:               'clone',
    forceHelperSize:      true,
    revert:               100,
		placeholder:          'item-list-draggable-placeholder',
    forcePlaceholderSize: true,
    opacity:              0.5,
    scroll:               true,
    scrollSensitivity:    20,
    scrollSpeed:          20,

    // leaving the cursorAt the point of grab and setting tolerance to pointer seems to work best for a
    // single item list (just shuffling); fixing te cursorAt and then requiring the 50% overlap causes
    // problems at the top and bottom of the list
    cursorAt:             false,
    tolerance:            'pointer',
    
    update: function(event, ui) {
      // user has dropped the item in an acceptable location

      // send request to the server to update the item list item positions
      var itemList = this;
      var droppedItem = ui.item;
      var droppedItemId = itemListsGetMoveItemId(droppedItem);
      var ordering = itemListsGetShuffleableOrdering(itemList);
      itemListsReportShuffleToServer(itemList, droppedItemId.id, ordering);

      // update the z-indexes
      itemListsResetZIndexes(itemList);
    }

  });

  // menu options for mobile
  itemListsSetupMoveEventHandlers(itemList);

  // disable shuffling if called for
  if ($(itemList).hasClass('disabled')) {
    $(itemList).sortable('disable');
  }
}

/*
 * itemListsHandleMoveDown
 * this method supports the itemListsHandleMove function when the up argument is set to false
 * -- ---- -- -- --
 * itemList:     the item list to which the move is being applied
 * movingItemId: the ID for the item being moved
 * ordering:     an array of item IDs (strings) the gives the current ordering of the item list in question
 * movingIndex:  the index of the item being moved in ordering
 */
function itemListsHandleMoveDown(itemList, movingItemId, ordering, movingIndex) {
  if (movingIndex < ordering.length - 1) {
    var followingItemIdString = ordering[movingIndex + 1];

    // move the element on the page
    var selectorStart = '.shuffleable-list-item' +
                        '[data-item-id=';
    $(selectorStart + movingItemId.idString + ']').insertAfter(selectorStart + followingItemIdString + ']');

    // tell the server it moved
    ordering.splice(movingIndex, 1); // remove the moving item from ordering
    ordering.splice(movingIndex + 1, 0, movingItemId.idString); // add it back in its new spot
    itemListsReportShuffleToServer(itemList, movingItemId.id, ordering);
  }
}

/*
 * itemListsHandleMoveUp
 * this method supports the itemListsHandleMove function when the up argument is set to true
 * -- ---- -- -- --
 * itemList:     the item list to which the move is being applied
 * movingItemId: the ID for the item being moved
 * ordering:     an array of item IDs (strings) the gives the current ordering of the item list in question
 * movingIndex:  the index of the item being moved in ordering
 */
function itemListsHandleMoveUp(itemList, movingItemId, ordering, movingIndex) {
  if (movingIndex > 0) {
    var precedingItemIdString = ordering[movingIndex - 1];

    // move the element on the page
    var selectorStart = '.shuffleable-list-item' +
                        '[data-item-id=';
    $(selectorStart + movingItemId.idString + ']').insertBefore(selectorStart + precedingItemIdString + ']');

    // tell the server it moved
    ordering.splice(movingIndex, 1); // remove the moving item from ordering
    ordering.splice(movingIndex - 1, 0, movingItemId.idString); // add it back in its new spot
    itemListsReportShuffleToServer(itemList, movingItemId.id, ordering);
  }
}

/*
 * itemListsReportShuffleToServer
 * shuffling (moving) starts in the browser, but needs to be reported the server to allow the server to
 * persist the changes that the user has requested
 * -- ---- -- -- --
 * itemList:     the item list that is being changed
 * movingItemId: the item ID of the item that has moved
 * ordering:     the ordering of the item list after the change
 */
function itemListsReportShuffleToServer(itemList, movingItemId, ordering) {
  var section = $(itemList).data('section');
  var shuffleUrl = $(itemList).data('shuffle-url');

  $.ajax({
    data:  { section:         section,
             dropped_item_id: movingItemId,
             ordering:        ordering },
    type:  'POST',
    url:   shuffleUrl,
    async: true
  });
}

/*
 * itemListsResetZIndexes
 * when a item list is "shuffled" the z-index get screwed up; this function resest the item list's z-indexes to 
 * not be screwed up
 * -- ---- -- -- --
 * itemList: the item list in question
 */
function itemListsResetZIndexes(itemList) {
  var zIndexWrappers = $(itemList).children();
  var nextZIndex = zIndexWrappers.length;
  zIndexWrappers.each(function(index, zIndexWrapper) {
    $(zIndexWrapper).css('z-index', nextZIndex--);
  });
}

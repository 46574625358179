// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
//import "channels"

Rails.start()
Turbolinks.start()
//ActiveStorage.start()

import "../monkey/bootstrap"
import "../src/tinymce.js"


// make JQuery available to view templates
import jQuery from "jquery"
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// generally needed
export * as PMApp from "./pmapp_combiner"

// devise layouts
import "../src/devise.js.erb"

// components
export * as CustomerSelector from "./customer_selector_combiner"
export * as JobSelector from "./job_selector_combiner"
export * as Rootable from "../src/rootable.js.erb"
export * as TeamFocus from "./team_focus_combiner"

import "../src/pmapp_sliders"
export * as Squirrelable from "../src/squirrelable.js.erb"
export * as Rosterable from "../src/rosterable.js.erb"
export * as Filterable from "../src/filterable.js.erb"
export * as Sortable from "../src/sortable.js.erb"
export * as ItemLists from "../src/item_lists.js.erb"
export * as TaskListable from "../src/task_listable.js.erb"
export * as JobListable from "../src/job_listable.js.erb"
export * as Attachable from "../src/attachable.js.erb"

// time buckets (show), worker dashboard
export * as Activities from "../src/activities.js.erb"
export * as NonLaborExpenses from "../src/non_labor_expenses.js.erb"
export * as Suspensions from "../src/suspensions.js.erb"
export * as Timers from "../src/timers.js.erb"

// time buckets (show)
export * as Assignments from "../src/task_worker_associations.js.erb"

// worker dashboard
export * as WorkerDashboard from "../src/worker_dashboard.js.erb"
import "../src/kpi.js.erb"

export * as Metricable from "../src/metricable.js.erb"
export * as Teams from "../src/team_member_binders.js.erb"


// support requests
export * as Billable from "./billable_combiner"
export * as Customers from "../src/customers.js.erb"
export * as CustomerDashboards from "../src/customer_dashboards.js.erb"
export * as Jobs from "../src/pmapp_jobs.js.erb"
export * as TimeBuckets from "./time_buckets_combiner"
import "../src/general_search.js.erb"
import "../src/projects.js.erb"
import "../src/support_requests.js.erb"
export * as JobDashboards from "../src/job_dashboards.js.erb"
import "../src/purchase_requests.js.erb"
import "../src/non_task_buckets.js.erb"
import "../src/locations.js.erb"
import "../src/customer_contacts.js.erb"
import "../src/hours_report.js.erb"
export * as CustomerRequests from "../src/customer_requests.js.erb"
export * as TaskManagers from "../src/task_managers.js.erb"
export * as Milestones from "../src/milestones.js.erb"
export * as PWAs from "../src/periodic_work_allocations.js.erb"
export * as BillingDocuments from "../src/billing_documents.js.erb"
import "../src/test_job_dashboard.js.erb"
import "../src/team_dashboards.js.erb"
import "../src/support_request_depot.js.erb"

/*
 * PMApp::worker_dashboard
 * This file contains JS that has been implemented specifically to support the worker dashboard.
 */

import "jquery-ui/ui/widgets/sortable"

import { filterableClear,
         filterableCountEnabledFilterButtons,
         filterableInitializeItemListAsFilterable } from './filterable.js.erb';
import { itemListsSetZIndexes                     } from './item_lists.js.erb';
import { jobListableSetupEventHandlers            } from './job_listable.js.erb';
import { showLoadingOverlay                       } from './loading_overlay';
import { pmappPreventDefaults                     } from './pmapp.js.erb';
import { squirrelableGetData                      } from './squirrelable.js.erb';
import { taskListableGetItemCount,
         taskListableInitialize,
         taskListableInitializeTaskListAsSortable } from './task_listable.js.erb';
import { teamFocusSetupEventHandlers              } from './team_focus.js.erb';


$(document).on('turbolinks:load', function() {

  if ($('body.workers.show').length) {
    teamFocusSetupEventHandlers();

    // task list
    taskListableInitialize('.entire-page');
    workerDashboardPopulateTaskListCount();
    workerDashboardHideShowTaskListFilterControls();

    // task list heading
    workerDashboardInitializeTaskListHeading();

    $('.item-list-heading [data-toggle="tooltip"]').tooltip();

    $('a.pipeline-sort-link').click(function(e) {
      handleMyPipelineSortRequest(e);
    });

    // job list
    jobListableSetupEventHandlers('.entire-page');

    // task and job list
    itemListsSetZIndexes();

    // timecard
    workerDashboardSetupTimecardWeekOffsetButtonEventHandlers();
  }
});


export function workerDashboardInitializeTaskListHeading() {
  workerDashboardPopulateTaskListCount();

  // filterableInitializeItemListAsFilterable sets up event handlers for the filter controls (which were
  // redrawn in sort_pipeline, above) and ensures that the filter buttons match up with the task list
  filterableInitializeItemListAsFilterable('TaskList');

  // workerDashboardHideShowTaskListFilterControls really just hides or shows the filter icon action
  // that hides/shows the filter controls based on whether or not the filter controls are useful
  workerDashboardHideShowTaskListFilterControls();
}


export function workerDashboardSetupTimecardWeekOffsetButtonEventHandlers() {
  $('.timecard-arrow-button').click(function(event) {
    event = event || window.event
    pmappPreventDefaults(event);

    var icon = event.target || event.srcElement;
    var clickedLink = $(icon).closest('a');

    var resquirrelableData = squirrelableGetData('timecard');
    var requestPath = $(clickedLink).attr('href');

    $.ajax({
      data:  resquirrelableData,
      type:  'POST',
      url:   requestPath,
      async: true
    });

  });
}

// TODO - I copied this from pmapp_jobs and modified it
export function workerDashboardHideShowTaskListFilterControls() {
  const sectionName = 'TaskList';

  var filterButtonSelector   = '#filterButton' + sectionName;
  var filterCollapseSelector = '#taskListFilterCollapse';

  var numActiveFilters = filterableCountEnabledFilterButtons(sectionName);

  if (numActiveFilters < 1) {
    // close the filter collapse
    $(filterCollapseSelector).collapse('hide');

    // hide the filter button
    $(filterButtonSelector).hide();

    // clear all filtering
    filterableClear(sectionName);
  }
  else {
    $(filterButtonSelector).show();
  }
}

export function workerDashboardPopulateTaskListCount() {
  const sectionName = 'TaskList';

  const countSelector = '#taskListHeading ' +
                        '.task-list-count';

  const itemCount = taskListableGetItemCount(sectionName);

  $(countSelector).html(
    '(' + itemCount.toString() + ')'
  );
}


// event handlers -- ---- -- -- --

/*
 * handleMyPipelineSortRequest
 * Use this function to post a request to the server to manipulate a worker pipeline.  Specifically, this function
 * will send the server information about which cards are minimized/normal so that the server can send the same
 * information back to the browser.
 */
export function handleMyPipelineSortRequest(event) {
  event = event || window.event
  pmappPreventDefaults(event);

  var icon = event.target || event.srcElement;
  var clickedLink = $(icon).closest('a');

  var requestPath = $(clickedLink).data("url");

  var squirreledParams = squirrelableGetData('sort');

  showLoadingOverlay();

  $.ajax({
    data:  squirreledParams,
    type:  'POST',
    url:   requestPath,
    async: true
  });
}


// sortable implementation -- ---- -- -- --

// TODO - move this to TaskListable???
function buildMinimizedParams(myPipeline) {
  var minimizedParams = [];
  var idStrings = myPipeline.sortable('toArray');
  for (var i = 0 ; i < idStrings.length ; i++) {
    var isMinimized = $('#' + idStrings[i] + ' .item-controls').hasClass('minimized');
    if (isMinimized) {
      var splitId = idStrings[i].split("-");
      var appId = splitId[splitId.length - 1];
      minimizedParams.push('minimized[]=' + appId);
    }
  }
  return minimizedParams;
}

/*
 * PMApp::rosterble
 * JS for the rosterable component
 */

import { pmappPreventDefaults } from './pmapp.js.erb';
import { pmappSlidersClose    } from "./pmapp_sliders";

const rosterableDraggable = {
  currentDroppable: null,
  element:          null,
  hotSpotClasses:   [],
  parentSelector:   null,
  teamMemberId:     null
};

$(document).on('turbolinks:load', function() {
  if ($('.rosterable').length > 0) {
    // the current page includes the roster

    // some browser behavior that I have not been able to specifically identify results in the firing of
    // turbolinks:load when there has not been a real page load; in some cases this happens long after a
    // session has been terminated and so there is no CSRF auth token; this timestamp check prevents a
    // flurry (one for each roster team member)  of unprocessable requests
    var now = new Date();
    var rosterableTimestamp = new Date($('.rosterable').data(
      'timestamp'
    ));
    if (now - rosterableTimestamp < 60000) {
      // do not request team member populates if the page was rendered more than 1 minute (60000 ms) ago
      rosterablePopulateAllTeamMembers();
    }

    var scrollTop = $(window).scrollTop();
    var viewPortHeight = $(window).height();

    rosterableSetRosterHeight(scrollTop, viewPortHeight);

    // allows the roster to move with the scroll until the header is off the page and then the roster sticks
    // at the top of the page; also adjusts the height of the roster so that the roster is always completely
    // contained by the page
    $(window).scroll(function(e) {
      var scrollTop = $(window).scrollTop();
      var viewPortHeight = $(window).height();

      rosterableSetRosterHeight(scrollTop, viewPortHeight);

      //var bopPosition = pageHeight - viewPortHeight - footerHeight;
      var headerHeight = rosterableGetHeaderHeight();
      if (scrollTop < headerHeight) {
        $('.rosterable').css('position', 'absolute');
      }
      else {
        $('.rosterable').css('position', 'fixed');
        $('.rosterable').css('top', '0px');
      }
    });
  }
});

function rosterableGetHeaderHeight() {
  return $('.entire-page > header').height();
}

export function rosterableSetRosterHeight(scrollTop, viewPortHeight) {
  // cannot use $(document).height() here to calculate the page height as, if the roster has many members,
  // the document height will be determined by the roster itself
  var headerHeight = rosterableGetHeaderHeight();
  var footerHeight = $('.entire-page > footer').height();
  var pageContentHeight = $('.page-content').height();
  var pageHeight = headerHeight + pageContentHeight + footerHeight;

  // the roster slide-out height must be less than or equal to the document height less the
  // header and footer heights; so first we compute that maximum height
  var scrollBottom = scrollTop + viewPortHeight;

  var rosterHeight = viewPortHeight;
  if (scrollTop < headerHeight) {
    rosterHeight -= (headerHeight - scrollTop);
  }

  if (scrollBottom >= pageHeight - footerHeight) {
    rosterHeight -= (footerHeight - (pageHeight - scrollBottom));
  }

  var rosterContainerPaddingTop = parseInt($('.rosterable-container').css('padding-top').replace('px', ''));
  var rosterContainerPaddingBottom = parseInt($('.rosterable-container').css('padding-bottom').replace('px', ''));
  var maxRosterContainerHeight = rosterHeight - rosterContainerPaddingTop - rosterContainerPaddingBottom;

  // next we must compute the natural height of the rosterable container
  var natRosterContainerHeight = $('#teamRosterGuts').height();

  // if the max height is less than the natural height, we need to set the height to the max
  // if the max height is greater than the natural height, we need to set the height to the natural height
  if (maxRosterContainerHeight < natRosterContainerHeight) {
    $('.rosterable-container').height(maxRosterContainerHeight);
  }
  else {
    $('.rosterable-container').height(natRosterContainerHeight);
  }
}


// event handlers -- ---- -- -- --

function rosterableHandleDraggableDrop(event) {
  if (rosterableDraggable.element) {
    $(rosterableDraggable.parentSelector).css("cursor", "");
    $(rosterableDraggable.parentSelector).off("mousemove");
    $(rosterableDraggable.parentSelector).off("mouseup");
    rosterableDraggable.element.remove();
    rosterableDraggable.element = null;

    var droppable = rosterableCheckForDroppable(event);

    if (droppable) {
      // call the droppable's receive handler
      var detail = rosterableBuildDraggableDetail(rosterableDraggable.teamMemberId, event.pageX, event.pageY);
      const receiveEvent = new CustomEvent('rosterableReceive', { detail: detail });
      droppable.dispatchEvent(receiveEvent);
    }

    $('.team-member').addClass('draggable');
  }
}

function rosterableHandleDraggableMove(event) {
  rosterableMoveDraggable(event);

  var droppable = rosterableCheckForDroppable(event);

  if (rosterableDraggable.currentDroppable != droppable) {
    if (rosterableDraggable.currentDroppable) {
      //  we just exited a droppable
      var detail = rosterableBuildDraggableDetail(rosterableDraggable.teamMemberId, event.pageX, event.pageY);
      const enterEvent = new CustomEvent('rosterableExit', { detail: detail });
      rosterableDraggable.currentDroppable.dispatchEvent(enterEvent);
    }

    rosterableDraggable.currentDroppable = droppable;
    if (rosterableDraggable.currentDroppable) {
      // we just entered a droppable
      var detail = rosterableBuildDraggableDetail(rosterableDraggable.teamMemberId, event.pageX, event.pageY);
      const exitEvent = new CustomEvent('rosterableEnter', { detail: detail });
      rosterableDraggable.currentDroppable.dispatchEvent(exitEvent);
    }
  }
}


// public functions -- ---- -- -- --

/*
 * rosterableCloseRoster
 * close the roster, if it is open; noop if the roster is already closed
 */
export function rosterableCloseRoster() {
  var sliderDiv = $('.rosterable.pmapp-slider');
  pmappSlidersClose(sliderDiv);
}

/*
 * 
 * rosterableInitializeAsDraggable
 * this method sets up the team roster to allow the user to drag team members onto other parts of the
 * application; for this to be useful, a page must also setup droppables that accept
 * Rosterable::TEAM_MEMBER_CLASS_NAME
 * -- ---- -- -- --
 * dragElementParentSelector: a CSS selector that identifies the element to which the drag element should
 *                            be appended; this will also define the dragging range/scope
 * hotSpotClass:              the class to be used to identify hotspots (elements on which team members can
 *                            be dropped)
 */
export function rosterableInitializeAsDraggable(dragElementParentSelector, hotSpotClass) {
  // if the parent selector has already been set, the roster has already been initialized as draggable
  // the only thing about initializing the roster for dragging that is droppable dependent is the hotspot
  // class; it is left to the calling programs to coordiante the parent selector, the first one will
  // always be used, subsequently supplied parent selector values are ignored
  rosterableDraggable.hotSpotClasses.push(hotSpotClass);
  if (! rosterableDraggable.parentSelector) {
    rosterableDraggable.parentSelector = dragElementParentSelector;

    $('.team-member').mousedown(function(event) {
      pmappPreventDefaults(event);

      // get the team member ID
      rosterableDraggable.teamMemberId = rosterableExtractWorkerId(this);

      // add the drag element to the page
      var imageSource = $(this).find('.roster-avatar').attr('src');
      var dragHtml    = "<figure id='rosterableDragElement' class='user-avatar user-avatar-nav'>" +
                        "<img src='" + imageSource + "' width='30' height='30'>" +
                        "</figure>";
      $(rosterableDraggable.parentSelector).append(dragHtml);
      $(rosterableDraggable.parentSelector).css("cursor", "move");

      rosterableDraggable.element = $('#rosterableDragElement');
      rosterableDraggable.element.css("position", "absolute");
      rosterableDraggable.element.css("z-index", "3");

      // move the newly created drag element under the cursor
      rosterableMoveDraggable(event);
      $('.team-member').removeClass('draggable');

      // add event handler to move the avatar as the cursor moves
      $(rosterableDraggable.parentSelector).mousemove(rosterableHandleDraggableMove);

      // add event handler to release the drag element when the mouse button is released
      // if the button is released over a droppable, also call the appropriate handler for that droppable
      $(rosterableDraggable.parentSelector).mouseup(rosterableHandleDraggableDrop);
    });

    $('.team-member').addClass('draggable');
  }
}

/*
 * rosterablePopulateAllTeamMembers
 * call this method to start an asynchronous process for each team member in the roster; the process will
 * populate the team member in the rendered roster
 */
export function rosterablePopulateAllTeamMembers() {
  // first reset rosterable so we know its okay to install event handlers on the new team members
  rosterableDraggable.parentSelector = null;
  rosterableDraggable.hotSpotClasses = [];

  // now, go ahead and populate each team member
  $('.rosterable .team-member').each(function(index) {
    rosterablePopulateTeamMemberContainer(this);
  });
}

export function rosterablePopulateTeamMember(teamMemberId) {
  var populateUrl = $('.rosterable').data('populate');

  $.ajax({
    data: { worker_id: teamMemberId },
    type: 'POST',
    url:   populateUrl,
    async: true
  });
}


// private functions -- ---- -- -- --

function rosterableBuildDraggableDetail(teamMemberId, xPos, yPos) {
  return { teamMemberId: teamMemberId,
           position:     { x: xPos, y: yPos }
         };
}

function rosterableCheckForDroppable(event) {
  var droppable = null;

  var overRoster = false;
  var overDroppable = false;
  var elementsBelow = document.elementsFromPoint(event.clientX, event.clientY);
  for (var i = 0 ; i < elementsBelow.length ; i++) {
    if ($(elementsBelow[i]).hasClass('rosterable-container') || $(elementsBelow[i]).hasClass('pmapp-slider-grab')) {
      overRoster = true;
    }
    for (var j = 0 ; j < rosterableDraggable.hotSpotClasses.length ; j++) {
      if ($(elementsBelow[i]).hasClass(rosterableDraggable.hotSpotClasses[j])) {
        overDroppable = true;
        droppable = elementsBelow[i];
      }
    }
  }

  if (overRoster) {
    droppable = null;
  }

  return droppable;
}

/*
 * rosterableExtractWorkerId
 * given a team member container from a team roster, returns the worker ID for the represented team member
 * -- ---- -- -- --
 * teamMemberContainer: the given container
 */
function rosterableExtractWorkerId(teamMemberContainer) {
  return $(teamMemberContainer).data('worker-id');
}

function rosterableMoveDraggable(event) {
  rosterableDraggable.element.css("left", event.pageX + 'px');
  rosterableDraggable.element.css("top",  event.pageY + 'px');
}

/*
 * rosterablePopulateTeamMemberContainer
 * starts an synchronous process to populate a team member in the rendered roster given the team member's
 * container
 * -- ---- -- -- --
 * teamMemberContainer - the CSS selector for the team member's containing DOM object
 */
function rosterablePopulateTeamMemberContainer(teamMemberContainer) {

  var teamMemberId = rosterableExtractWorkerId(teamMemberContainer);
  rosterablePopulateTeamMember(teamMemberId);
}

function rosterableSetPosition() {
  var position = $('.entire-page > header').height();
  $('.rosterable').css('top', position + 'px');
}

/*
 * PMApp::pmapp_jobs
 * This file contains JS that has been implemented specifically to support the job dashboards.
 */

import "jquery-ui/ui/widgets/sortable";
import ClipboardJS from "clipboard/dist/clipboard.min.js";

import { hideShowCategoryControls                  } from './billable.js.erb';
import { customerSelectorAddFieldsToParams,
         customerSelectorSetupEventHandlers        } from './customer_selector.js.erb';
import { filterableClear,
         filterableCountEnabledFilterButtons,
         filterableInitializeItemListAsFilterable  } from './filterable.js.erb';
import { itemListsInitializeItemListAsShuffleable,
         itemListsSetZIndexes                      } from './item_lists.js.erb';
import { showLoadingOverlay                        } from './loading_overlay';
import { pmappPreventDefaults                      } from './pmapp.js.erb';
import { rosterableInitializeAsDraggable           } from './rosterable.js.erb';
import { squirrelableGetData                       } from './squirrelable.js.erb';
import { taskListableGetItemCount,
         taskListableInitialize                    } from './task_listable.js.erb';
import { teamFocusSetupEventHandlers               } from './team_focus.js.erb';
import { pmappTinyMCEInitializeMentionable,
         pmappTinyMCESetupModalCleanupEventHandler } from './tinymce.js.erb';
import { workerAvatarListInitializePopovers,
         workerAvatarListDestroyPopovers           } from './worker_avatar_list.js.erb';



// -- ---- -- -- --
// INITIALIZATION

$(document).on('turbolinks:load', function() {

  // initialization for the job dashbaord
  if ($('body.pmapp-job.show').length) {
    new ClipboardJS('.clipboard-btn');

    teamFocusSetupEventHandlers();

    taskListableInitialize('.entire-page',
                           '#pmappJobTaskListContainer');

    filterableInitializeItemListAsFilterable('pmappJobs');
    pmappJobsPopulateTaskListCount();
    pmappJobsHideShowFilterControls();

    pmappJobsInitializeNonLaborExpensesAsShuffleable();
    pmappJobsInitializeReceiptsAsSortable();

    pmappJobsSetupCloseEventHandler();
    pmappJobsSetupReopenEventHandler();

    $('a.job-action-option-delete').click(function() {
      showLoadingOverlay();
    });

    pmappJobsInitializeContributorPopovers();
    pmappJobsInitializeForDragAndDropOwnerAssignment();

    pmappJobsCheckIfProgressBarContainerIsEmpty();

    pmappJobsSetupBillingInfoEventHandlers();

    pmappJobsSetupBudgetItemFinders();

    itemListsSetZIndexes();
  }
});


// -- ---- -- -- --
// JOB DASHBOARD HELPERS


// public

export function pmappJobsSetupBudgetItemFinders() {
  $('.scroll-to').click(function (event) {
    var clickedNode = event.target || event.srcElement;
    var destinationId = $(clickedNode).data('scroll');
    document.getElementById(destinationId).scrollIntoView({ behavior: "smooth" });
  });
}

/*
 * TODO - I really don't like using JS for this.  Need to fine an all ruby/CSS solution.
 * pmappJobsCheckIfProgressBarContainerIsEmpty
 * To keep proper spacing in the job dashboard overview card, this function check to see if the progress bar
 * container div is effectively empty, and if so, adds a class to the container to indicate as much.
 */
export function pmappJobsCheckIfProgressBarContainerIsEmpty() {
  if ($('.job-task-progress').children().length == 0 &&
      $('.job-relative-progress').children().length == 0 &&
      $('#nonLaborProgressBarContainer').children().length == 0 &&
      $('#budgetRollupContainer').children().length == 0) {
    $('.job-progress-bar-container').addClass('empty');
  }
  else {
    $('.job-progress-bar-container').removeClass('empty');
  }
}

/*
 * pmappJobsDestroyContributorPopovers
 * dispose of popovers that have been created for the contributors section; this is used whenever the
 * contributors section is redrawn
 */
export function pmappJobsDestroyContributorPopovers() {
  var selector = '#jobContributorsContainer';
  workerAvatarListDestroyPopovers(selector);
}

/*
 * pmappJobsHideShowFilterControls
 * determines whether or not the task list filter controls should be present based on the content of the
 * task list
 */
export function pmappJobsHideShowFilterControls() {
  var filterButtonSelector   = '#filterButton';
  var filterCollapseSelector = '#filterCollapse';

  var sectionName = 'pmappJobs';
  var numActiveFilters = filterableCountEnabledFilterButtons(sectionName);

  if (numActiveFilters < 1) {
    // close the filter collapse
    $(filterCollapseSelector).collapse('hide');

    // hide the filter button
    $(filterButtonSelector).hide();

    // clear all filtering
    filterableClear(sectionName);
  }
  else {
    $(filterButtonSelector).show();
  }
}

/*
 * pmappJobsInitializeContributorPopovers
 * initializes the contributors section in the job overiew section so that clicking a contributor
 * results in a bootstrap popover
 */
export function pmappJobsInitializeContributorPopovers() {
  const withBodySelector = '#jobContributorsContainer >';
  const bodylessSelector = '#jobContributorsContainer ' +
                           '.owner-wrapper';
  const allSelector      = '#jobContributorsContainer';
  workerAvatarListInitializePopovers(bodylessSelector, withBodySelector, allSelector)
}

/*
 * pmappJobsInitializeNonLaborExpensesAsShuffleable
 * this method uses jQuery UI sortable to enable the drag/drop featues of the non-labor expense list
 */
export function pmappJobsInitializeNonLaborExpensesAsShuffleable() {
  const listSelector = pmappJobsBuildNLEListSelector();
  const itemList = $(listSelector)[0];
  itemListsInitializeItemListAsShuffleable(itemList);
}

/*
 * pmappJobsInitializeReceiptsAsSortable
 * this method uses jQuery UI sortable to enable the drag/drop featues of the non-labor expense list
 */
function pmappJobsInitializeReceiptsAsSortable() {
  const listSelector = pmappJobsBuildReceiptListSelector();
  const itemList = $(listSelector)[0];
  itemListsInitializeItemListAsShuffleable(itemList);
}

export function pmappJobsPopulateTaskListCount() {
  const sectionName = 'pmappJobs';

  const countSelector = '#pmappJobTaskListHeading ' +
                        '.task-list-count';

  const itemCount = taskListableGetItemCount(sectionName);

  $(countSelector).html(
    '(' + itemCount.toString() + ')'
  );
}

export function pmappJobsSetupBillingInfoEventHandlers() {
  $('.job-billing-info-select').hover(
    function(e) { // hover in
      var optionsDiv = pmappJobsFindBillingInfoSelectOptionsDiv(e)
      $(optionsDiv).css('display', 'block');
    },
    function(e) { // hover out
      var optionsDiv = pmappJobsFindBillingInfoSelectOptionsDiv(e)
      $(optionsDiv).css('display', 'none');
    }
  );

  $('.current-selection').click(
    function(e) {
      var optionsDiv = pmappJobsFindBillingInfoSelectOptionsDiv(e)
      $(optionsDiv).toggle();
    }
  );
}

/*
 * pmappJobsSetupCloseEventHandler
 * the close job button is handled by JS to ensure that the information that has been squirreled away for the
 * close action is passed to the close action
 */
export function pmappJobsSetupCloseEventHandler() {
  pmappJobsSetupSquirrelFedButtonEventHandler('job-action-option-close',
                                              'close');
}

/*
 * pmappJobsSetupReopenEventHandler
 * the re-open job button is handled by JS to ensure that the information that has been squirreled away for the
 * reopen action is passed to the reopen action
 */
export function pmappJobsSetupReopenEventHandler() {
  pmappJobsSetupSquirrelFedButtonEventHandler('job-action-option-reopen',
                                              'reopen');
}

// private

/*
 * pmappJobsAcceptDropTeamMember
 * checks if a a team member (worker) from a team roster can be dropped on a owner assignment hotspot
 * -- ---- -- -- --
 * teamMemberId: the ID of the team member in question
 */
function pmappJobsAcceptDropTeamMember(teamMemberId) {
  var rVal = true;

  // for the most part the server controls when a team member can be dropped by either making an area a hotspot
  // or not

  // don't accept if the team member in question is already the owner
  var ownerAvatarSelector = '#jobContributorsContainer ' +
                            '.owner-wrapper ' +
                            'figure[data-owner-id=' + teamMemberId + ']';
  if ($(ownerAvatarSelector).length > 0) {
    rVal = false;
  }

  return rVal;
}

/*
 * pmappJobsBuildNLEListSelector
 * used to select the entire NLE list
 */
function pmappJobsBuildNLEListSelector() {
  return '#nonLaborExpenseList';
}

/*
 * pmappJobsBuildReceiptListSelector
 * used to select the entire receipt list
 */
function pmappJobsBuildReceiptListSelector() {
  return '#receiptList';
}

function pmappJobsFindBillingInfoSelectOptionsDiv(e) {
  e = e || window.event
  var triggeringElement = e.target || e.srcElement;
  var wrapper = $(triggeringElement).closest('.job-billing-info-select');
  return $(wrapper).find('.selection-options');
}

/*
 * pmappJobsGetSortableOrdering
 * builds the current ordering of a sortable task list
 * -- ---- -- -- --
 * nleList: the sortable list in question
 */
function pmappJobsGetSortableOrdering(nleList) {
  return $(nleList).sortable('toArray');
}

/*
 * pmappJobsHandleDropTeamMember
 * handle the user dropping an team member (worker) from a team roster on a job hot spot
 * -- ---- -- -- --
 * drop method for jQuery UI droppable
 */
function pmappJobsHandleDropTeamMember(e) {
  e = e || window.event
  var droppable = e.target || e.srcElement;

  // un-highlight the receiving droppable
  $(droppable).removeClass("will-accept");

  // handle the drop itself
  var teamMemberId = e.detail.teamMemberId;

  // the UI should have indicated that the team member could not be dropped on the hotspot, but there really
  // isn't anything preventing the user from doing it anyway, so we check to be sure it should be allowed
  // to make sure nothing happends
  if (pmappJobsAcceptDropTeamMember(teamMemberId)) {
    var setOwnerUrl  = $(this).data("set-owner-url");

    $.ajax({
      data:  { worker_id: teamMemberId },
      type:  'POST',
      url:   setOwnerUrl,
      async: true
    });
  }
}

/*
 * pmappJobsHandleMove
 * handle a user tapping a "Move up" or "Move down" action
 * -- ---- -- -- --
 * event: the event generated by the click
 * up:    true if a "Move up" was tapped; false if a "Move down" was tapped
 */
function pmappJobsHandleMove(event, up) {
  event = event || window.event

  event.preventDefault();
  // don't stop propagation, we want bootstap to close the menu

  var icon = event.target || event.srcElement;
  var clickedLink = $(icon).closest('a');

  var primaryListSelector = pmappJobsBuildPrimaryNLEListSelector();
  var receiptMaterialItemListSelector = pmappJobsBuildReceiptNLEListSelector();

  var nleList = $(clickedLink).closest(receiptMaterialItemListSelector);
  if (nleList.length == 0) {
    nleList = $(clickedLink).closest(primaryListSelector);
  }

  var movingItem = $(clickedLink).closest('.draggable-element');
  var movingItemId = movingItem.attr('id');
  var ordering = pmappJobsGetSortableOrdering(nleList);
  var movingIndex = ordering.indexOf(movingItemId);

  if (up) {
    pmappJobsHandleMoveUp(nleList, movingItemId, ordering, movingIndex);
  }
  else {
    pmappJobsHandleMoveDown(nleList, movingItemId, ordering, movingIndex);
  }

  pmappJobsResetZIndexes(nleList);
}

/*
 * pmappJobsHandleMoveDown
 * this method supports the pmappJobsHandleMove function when the up argument is set to false
 * -- ---- -- -- --
 * nleList:     the list to which the move is being applied
 * movingItemId: the HTML ID for the item being moved
 * ordering:     an array of item HTML IDs that gives the current ordering of the NLE list in question
 * movingIndex:  the index of the item being moved in ordering
 */
function pmappJobsHandleMoveDown(nleList, movingItemId, ordering, movingIndex) {
  if (movingIndex < ordering.length - 1) {
    var followingItemId = ordering[movingIndex + 1];

    // move the element on the page
    var selectorStart = '.draggable-element#';
    $(selectorStart + movingItemId).insertAfter(selectorStart + followingItemId);

    // tell the server it moved
    ordering.splice(movingIndex, 1); // remove the moving item from ordering
    ordering.splice(movingIndex + 1, 0, movingItemId); // add it back in its new spot
    pmappJobsReportSortToServer(nleList, movingItemId, ordering);
  }
}

/*
 * pmappJobsHandleMoveUp
 * this method supports the pmappJobsHandleMove function when the up argument is set to true
 * -- ---- -- -- --
 * nleList:      the list to which the move is being applied
 * movingItemId: the HTML ID for the item being moved
 * ordering:     an array of item HTML IDs that gives the current ordering of the task list in question
 * movingIndex:  the index of the item being moved in ordering
 */
function pmappJobsHandleMoveUp(nleList, movingItemId, ordering, movingIndex) {
  if (movingIndex > 0) {
    var precedingItemId = ordering[movingIndex - 1];

    // move the element on the page
    var selectorStart = '.draggable-element#';
    $(selectorStart + movingItemId).insertBefore(selectorStart + precedingItemId);

    // tell the server it moved
    ordering.splice(movingIndex, 1); // remove the moving item from ordering
    ordering.splice(movingIndex - 1, 0, movingItemId); // add it back in its new spot
    pmappJobsReportSortToServer(nleList, movingItemId, ordering);
  }
}

/*
 * pmappJobsInitializeForDragAndDropOwnerAssignment
 * this method sets up hot spots for dropping workers, from the team roster, in order to make the dropped worker
 * the new job owner
 */
function pmappJobsInitializeForDragAndDropOwnerAssignment() {
  // the server will decide if dragging team members to assign the project owner should be allowed
  // if so, there will be at least one element with the class PmappJobsController::SET_OWNER_HOTSPOT_CLASS_NAME
  if ($('.set-owner-hotspot').length > 0) {
    rosterableInitializeAsDraggable('.entire-page',
                                    'set-owner-hotspot');

    $('.set-owner-hotspot').on("rosterableEnter", function (e) {
      e = e || window.event
      var droppable = e.target || e.srcElement;
      if (pmappJobsAcceptDropTeamMember(e.detail.teamMemberId)) {
        $(droppable).addClass("will-accept");
      }
    });

    $('.set-owner-hotspot').on("rosterableExit", function (e) {
      e = e || window.event
      var droppable = e.target || e.srcElement;
      $(droppable).removeClass("will-accept");
    });

    $('.set-owner-hotspot').on("rosterableReceive",
                                                                     pmappJobsHandleDropTeamMember);
  }
}

/*
 * pmappJobsReportSortToServer
 * sorting starts in the browser, but needs to be reported the server to allow the server to persist the
 * sorting that the user has requested
 * -- ---- -- -- --
 * nleList:   the NLE list that is being sorted
 * itemId:    the item HTML ID of the item that has moved
 * sortedIds: the ordering of the NLE list after sorting
 */
function pmappJobsReportSortToServer(nleList, itemId, sortedIds) {

  /*
   * the server sets up the paths (on data attributes) to be list specific; so we really just need to
   * grab that path and send the request; the server can figure out what it needs to do from itemId and
   * sortedIds
   */

  var updatePositionPath = $(nleList).data("url");

  $.ajax({
    data:  { item:         itemId,
             sorted_items: sortedIds },
    type:  'POST',
    url:   updatePositionPath,
    async: true
  });
}

/*
 * pmappJobsResetZIndexes
 * when a NLE list is "sorted" the z-indexes get screwed up; this function resest the list's z-indexes to 
 * not be screwed up
 * -- ---- -- -- --
 * nleList: the NLE list in question
 */
function pmappJobsResetZIndexes(nleList) {
  var zIndexWrappers = $(nleList).children();
  var nextZIndex = zIndexWrappers.length;
  zIndexWrappers.each(function(index, zIndexWrapper) {
    $(zIndexWrapper).css('z-index', nextZIndex--);
  });
}

/*
 * pmappJobsSetupSquirrelFedButtonEventHandler
 * handles a button tap when the button is to trigger an action that requires informaiton that has been
 * squirrelled away
 * -- ---- -- -- --
 * buttonClassName: the class name of the subject button; need to set up the event handler
 * squirrelKey:     the name of the action for which information has been squirreled away
 */
function pmappJobsSetupSquirrelFedButtonEventHandler(buttonClassName, squirrelKey) {
  $('a.' + buttonClassName).on('click', function(event) {
    event = event || window.event
    pmappPreventDefaults(event);

    var icon = event.target || event.srcElement;
    var clickedLink = $(icon).closest('a');

    var squirreledParams = squirrelableGetData(squirrelKey);
    var url = clickedLink.attr('href');

    var confirmationMessage = clickedLink.data('confirm');
    if (! confirmationMessage) {
      confirmationMessage = clickedLink.data('loading-confirm');
    }

    if (! confirmationMessage || (confirmationMessage && confirm(confirmationMessage))) {
      showLoadingOverlay();

      $.ajax({
        data:  squirreledParams,
        type:  'POST',
        url:   url,
        async: true
      });
    }
  });
}


// -- ---- -- -- --
// NEW/EDIT FORM HELPERS

// public

/*
 * pmappJobsGenerateCustomerSelectorParams
 * used to ensure that customer selector params are passed with a team focus update controls callback action
 */
export function pmappJobsGenerateCustomerSelectorParams() {
  return customerSelectorAddFieldsToParams({});
}

/*
 * pmappJobsInitializeNewEditForm(
 * used after rendering the new/edit form
 * -- ---- -- -- --
 * forModal: true if the new/edit form will be rendered in a modal; false otherwise
 */
export function pmappJobsInitializeNewEditForm(forModal = false) {
  customerSelectorSetupEventHandlers();
  teamFocusSetupEventHandlers(pmappJobsGenerateCustomerSelectorParams);

  // setup event handler for the "owning team" select
  $('#ownerTeamSelect').change(function(e) {
    e = e || window.event
    var triggeringSelect = e.target || e.srcElement;
    pmappJobsHandleOwnerTeamSelectChange(triggeringSelect);
  });

  // setup event handler for the owner select
  $('#ownerSelect').change(function(e) {
    e = e || window.event
    var triggeringSelect = e.target || e.srcElement;
    pmappJobsHandleOwnerSelectChange(triggeringSelect);
  });

  hideShowCategoryControls('categoryControlsWrapper',
                           'workTypeControlsWrapper');

  $('#billingTeamSelect').change(function(e) {
    e = e || window.event
    var billingTeamSelect = e.target || e.srcElement;
    pmappJobsHandleBillingTeamSelectChange(billingTeamSelect);
  });

  if (forModal) {
    var tinyMCEClassName = 'tinymce-mentionable';
    pmappTinyMCEInitializeMentionable(tinyMCEClassName);

    var modalId = 'jobFormModal';
    pmappTinyMCESetupModalCleanupEventHandler(modalId, tinyMCEClassName);
  }
  else {
    $('form.job-form').submit(function() {
      showLoadingOverlay();
    });
  }
}

// private

function pmappJobsHandleBillingTeamSelectChange(triggeringSelect) {

  var selectedBillingTeamId = $(triggeringSelect).children('option:selected').val();

  var params = {
    team_id: selectedBillingTeamId
  }

  var updateUrlKey = 'change-billing-team-url';
  var updateUrl = $(triggeringSelect).data(updateUrlKey);

  $.ajax({
    data:  customerSelectorAddFieldsToParams(params),
    type:  'POST',
    url:   updateUrl,
    async: true
  });
}

/*
 * pmappJobsHandleOwnerSelectChange
 * invokes the change_owner action in the appropriate jobs controller; this is necessary to make any changes
 * that may be needed due to a change in the "projected owning team"
 * -- ---- -- -- --
 * triggeringSelect: a JS handle to the owner select
 */
function pmappJobsHandleOwnerSelectChange(triggeringSelect) {

  var selectedOwnerId = $('#ownerSelect').
                          children('option:selected').val();
  var workTypeTeamId  = $('#workTypeControlsWrapper select').
                          attr('data-team-id');

  var params = {
    owner_id:          selectedOwnerId,
    work_type_team_id: workTypeTeamId
  };

  var updateUrlKey = 'url';
  var updateUrl = $(triggeringSelect).data(updateUrlKey);

  $.ajax({
    data:  customerSelectorAddFieldsToParams(params),
    type:  'POST',
    url:   updateUrl,
    async: true
  });
}

/*
 * pmappJobsHandleOwnerTeamSelectChange
 * invokes the change_owner_team action in the appropriate jobs controller; this is necessary to (1) change
 * the list of team members in the owner select to match the selected team, and (2) make any changes that may
 * be needed due to a change in the "projected owning team"
 * -- ---- -- -- --
 * triggeringSelect: a JS handle to the owner team select
 */
function pmappJobsHandleOwnerTeamSelectChange(triggeringSelect) {

  var selectedOwnerTeamId = $('#ownerTeamSelect').
                              children('option:selected').val();
  var workTypeTeamId      = $('#workTypeControlsWrapper select').
                              attr('data-team-id');

  var params = {
    team_id:     selectedOwnerTeamId,
    work_type_team_id: workTypeTeamId
  };


  var updateUrlKey = 'url';
  var updateUrl = $(triggeringSelect).data(updateUrlKey);

  $.ajax({
    data:  customerSelectorAddFieldsToParams(params),
    type:  'POST',
    url:   updateUrl,
    async: true
  });
}
